<template>
  <v-container class="d-flex flex-wrap justify-start my-5" style="gap: 20px;min-height: 90vh;">
    <VenueItem v-for="venue of venues" :venue="venue" :key="venue.name"/>
  </v-container>
</template>

<script>
import VenueItem from "@/components/venue/VenueItem";
import {getAllVenues} from "@/services/venue.service";
export default {
  name: "AllVenues",
  components: {VenueItem},
  data(){
    return{
      venues:[]
    }
  },
  methods:{
    async getAllVenues() {
      this.venues = await getAllVenues();
    }
  },
  created() {
    this.getAllVenues();
  }
}
</script>

<style scoped>

</style>