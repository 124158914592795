<template>
  <v-responsive>
    <v-app id="app" style="overflow-x: hidden">
      <div class="overlay-2024"></div>
      <CartDrawer />
      <Topbar v-if="!hiddenHeaderAndFooter" />
      <v-main order="1">
        <v-img
          width="1000px"
          :src="require('./assets/logo.png')"
          style="
            pointer-events: none;
            position: absolute;
            bottom: 0;
            right: -100px;
            opacity: 0.02;
          "
        ></v-img>
        <router-view @scrollTop="scrollTop" class="" />
      </v-main>
      <AppNotification />
      <Mobilebar v-if="!$route.meta.anonymous && !hiddenHeaderAndFooter" />
      <CognitoLoginPopup />
    </v-app>
  </v-responsive>
</template>

<script>
import Topbar from "@/views/Navigation/Topbar";
import { findChatMessage } from "@/services/socket.api";
import { getUpdatedCurrentUser, loadContacts } from "@/services/chat.service";
import Mobilebar from "@/views/Navigation/Mobilebar";
import Notify from "@/models/notification";
import CognitoLoginPopup from "@/views/CognitoLoginPopup";
import { NotificationsSound } from "@/services/sound.service";
import * as StompJs from "@stomp/stompjs";
import AppNotification from "@/AppNotification";
import CartDrawer from "@/components/cart/CartDrawer.vue";
export default {
  name: "App",
  components: {
    CartDrawer,
    AppNotification,
    CognitoLoginPopup,
    Mobilebar,
    Topbar,
  },
  data() {
    return {
      heightNav: null,
      deferredPrompt: null,
    };
  },

  created() {
    //this.connect();
  },
  computed: {
    hiddenHeaderAndFooter() {
      return this.$route.meta.hiddenHeaderAndFooter;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile device
        return true;
      } else {
        // false for not mobile device
        return false;
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    darkModeSetting() {
      return this.$store.state.auth.settings;
    },
  },
  mounted() {
    this.$store.state.app.initialHistory = window.history.length;
  },
  watch: {
    darkModeSetting: {
      handler() {
        //if(this.$store.state.auth.settings) this.darkMode(this.$store.state.auth.settings.darkMode)
      },
      deep: true,
    },
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.mainContent.scrollTop = 0;
      });
    },

    connect() {
      if (!this.currentUser) {
        return null;
      }
      //const Stomp = require("@stomp/stompjs");
      const Stomp = StompJs.Stomp;
      let SockJS = require("sockjs-client");
      SockJS = new SockJS(process.env.VUE_APP_HOST + "/ws");
      this.$store.dispatch("chat/connect", Stomp.over(SockJS));
      this.$store.state.chat.stompClient.reconnect_delay = 5000;
      this.$store.state.chat.stompClient.connect(
        {},
        this.onConnected,
        this.onError
      );
    },

    onConnected() {
      this.$store.dispatch("chat/changeConnectionStatus", true);
      console.log("connected");
      this.$store.state.chat.stompClient.subscribe(
        "/user/" + this.currentUser.id + "/queue/messages",
        this.onMessageReceived
      );
    },

    onError(err) {
      console.log("Error in Websocket!");
      console.log(err);
      this.$store.dispatch("chat/changeConnectionStatus", false);
      console.log("STOMP: " + err);

      setTimeout(this.connect(), 10000);
      console.log("STOMP: Reconecting in 10 seconds");
    },
    onMessageReceived(msg) {
      const notification = JSON.parse(msg.body);

      const active = this.$store.state.chat.activeContact;

      if (
        active &&
        active.id == notification.senderId &&
        this.$route.name === "activeChat"
      ) {
        findChatMessage(notification.id).then((message) => {
          const newMessages = this.$store.state.chat.messages;
          newMessages.push(message);
          NotificationsSound();
          this.$store.dispatch("chat/messages", newMessages);
          loadContacts();
        });
      } else {
        this.$store.dispatch(
          "notificationsStore/add",
          new Notify(
            null,
            notification.senderName + ":",
            false,
            notification.content
          )
        );
        NotificationsSound();
        getUpdatedCurrentUser();

        console.log("Received a new message in other Chat", notification);
      }
    },
  },
};
</script>

<style></style>
