<template>
  <v-card rounded="lg">
    <!-- <v-toolbar flat>
      <v-btn icon @click="$emit('toggleDialog', false)"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <v-toolbar-title>Post on your feed</v-toolbar-title>
    </v-toolbar> -->
    <v-btn
      icon
      variant="plain"
      class="position-absolute top-0 right-0"
      @click="$emit('toggleDialog', false)"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <template #text>
      <div
        style="width: auto; height: 400px; position: relative"
        class="mx-auto mt-7"
      >
        <v-img
          cover
          class="rounded-t-lg rounded-b"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          width="100%"
          height="100%"
          :src="
            image
              ? image
              : require('../../assets/Placeholder-Profile-819x1024.webp')
          "
        >
        </v-img>
        <div
          v-if="!image"
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60px;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
          "
        >
          <v-icon size="32" color="white" icon="mdi-camera-plus"></v-icon>
        </div>
        <input
          type="file"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
          "
          placeholder="File"
          @change="setImage"
        />
      </div>
      <v-spacer style="height: 10px" />
      <v-textarea
        variant="outlined"
        tile
        rounded="b-lg"
        :rules="[(v) => v && v.length <= 255]"
        :counter="255"
        height="100%"
        v-model="feed.text"
        placeholder="Add Caption..."
      />
    </template>
    <v-card-actions>
      <v-btn
        :loading="loading"
        rounded="lg"
        size="x-large"
        variant="flat"
        block
        tile
        color="primary"
        :disabled="!valid"
        @click="save"
        >Publish new post</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import CreateFeedItem from "@/models/CreateFeedItem";
import { compressImage } from "@/services/image.service";

export default {
  name: "CreateFeedItem",
  data() {
    return {
      loading: false,
      feed: new CreateFeedItem(),
      image: null,
    };
  },
  computed: {
    valid() {
      if (this.feed.text && this.feed.text.length <= 255) return true;
      else return false;
    },
  },
  methods: {
    async save() {
      console.log(this.feed);
      this.loading = true;
      await this.$store.dispatch("feed/createItem", this.feed);
      this.loading = false;
      this.$emit("toggleDialog", false);
    },
    async setImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        let compressedFile = compressImage(files[0]).file;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.feed.file = event.target.result.split(",")[1];
        };
        reader.readAsDataURL(compressedFile);

        this.image = URL.createObjectURL(files[0]);
      }
    },
  },
};
</script>

<style scoped></style>
