<template>
  <div style="">
    <span v-if="cart.length <= 0">No Products</span>
    <ProductItem
      v-for="product of cart"
      :cart-response="product"
      :key="product.uid"
    />
    <v-divider class="mt-5"></v-divider>
    <div class="d-flex justify-space-between font-weight-bold">
      <div>Total</div>
      <div v-text="total"></div>
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/cart/checkout/ProductItem.vue";

export default {
  name: "ProductOverview",
  components: { ProductItem },
  watch: {
    totalItems() {
      this.$emit("amount", this.totalItems);
    },
  },
  computed: {
    totalItems() {
      return this.cart.length;
    },
    total() {
      let Euro = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      let response = 0;
      for (let item of this.cart) {
        response += item.amount * item.product.price;
      }
      return Euro.format(response);
    },
    cart() {
      return this.$store.state.cart.cart;
    },
  },
};
</script>

<style scoped></style>
