<template>
  <v-list-item three-line class="offwhite mx-5 my-2" :class="{'phantom':isPhantom}">
    <template #prepend>
      <v-avatar size="50">
        <v-img :src="user.profilePicture ? 'data:image/png;base64,' + user.profilePicture : require('@/assets/Placeholder-Profile-819x1024.webp')" />

      </v-avatar>
    </template>
    <template #title>
      <span class="font-weight-bold mr-5" style="font-size: 1.3rem;"><span v-html="user.name"></span></span><br/>
      <span v-text="user.fullName"></span><br/>
      <div class="text-wrap d-flex justify-start flex-wrap">
        <v-chip class="text-wrap my-1" x-small v-for="role of user.roles" :key="role">{{role}}</v-chip>
      </div>
    </template>
    <template #subtitle>
      <span v-for="(profession, index) of user.professions" :key="index">
        <span class="mx-1" v-text="profession.company?.name"></span>
        <span v-if="index < user.professions.length - 1">|</span>
      </span>
    </template>
    <template #append>
      <v-menu offset-y bottom left>
        <template #activator="{ props }">
          <v-btn dark icon v-bind="props">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-dialog v-model="showEditUserDialog">
            <template #activator="{ props }">
              <v-list-item v-bind="props" link>
                <template #title>Edit User</template>
                <template #prepend>
                  <v-icon>mdi-pencil</v-icon>
                </template>
              </v-list-item>
            </template>
            <EditUserDialogContent @toggle="(value) => this.showEditUserDialog = value" :user-id="userC.id"/>
          </v-dialog>
          <v-dialog v-model="showEditRoles">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <template #title>Edit Roles</template>
                <template #prepend>
                  <v-icon>mdi-account-settings</v-icon>
                </template>
              </v-list-item>
            </template>
            <v-sheet class="pa-5">
              <EditUserRoles :user-id="user.id" @updateUser="updateUser" :selected-roles="user.roles"/>
            </v-sheet>
          </v-dialog>
          <v-dialog v-model="sendMailDialog" width="600px">
            <template #activator="{ props }">
              <v-list-item v-bind="props">
                <template #title>Send Welcome</template>
                <template #prepend>
                  <v-icon>mdi-send</v-icon>
                </template>
              </v-list-item>
            </template>
            <SendAccountEmail :user-id="user.id" @close="() => this.sendMailDialog = false"/>
          </v-dialog>
          <v-list-item link @click="deleteUser">
            <template #title>Delete User</template>
            <template #prepend>
              <v-icon>mdi-delete</v-icon>
            </template>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-tooltip right>
      <template #activator="{ props }">
        <v-icon v-bind="props" style="position:absolute;left:0;top:0;" class="pa-2" v-if="isPhantom">mdi-ghost</v-icon>
      </template>
      This User has a temporary Email and has not logged in in his account.
    </v-tooltip>
  </v-list-item>
</template>

<script>
import EditUserRoles from "@/components/user/EditUserRoles";
import SendAccountEmail from "@/components/management/user/SendAccountEmail";
import EditUserDialogContent from "@/components/user/EditUserDialogContent";
import { deleteUser } from "@/services/user.service";

export default {
  name: "AdminUserListItem",
  components: { EditUserDialogContent, SendAccountEmail, EditUserRoles },
  props: {
    user: Object
  },
  data() {
    return {
      sendMailDialog: false,
      showEditUserDialog: false,
      showEditRoles: false
    };
  },
  methods: {
    async deleteUser() {
      if (confirm("Are you sure?")) {
        let success = await deleteUser(this.userC.id);
        if (success) this.$emit("deleteUser", this.userC.id);
      }
    },
    updateUser(user) {
      this.$emit("updateUser", user);
      this.showEditRoles = false;
    }
  },
  computed: {
    userC: {
      get() {
        return this.user;
      },
      set(user) {
        this.$emit("updateUser", user);
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    hasRoleAdmin() {
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
    isPhantom() {
      return this.userC.roles.includes("ROLE_PHANTOM");
    }
  }
};
</script>