<template>
  <div v-if="event">
    <div
      style="
        position: relative;
        background: linear-gradient(
            255deg,
            rgba(131, 52, 146, 1) 0%,
            black 80vw
          ),
          black;
      "
      class="d-flex align-center"
    >
      <v-container class="d-flex flex-wrap justify-center align-start">
        <h1
          style="
            width: 100%;
            color: #ffffff24;
            font-family: 'Neue Singular H', Sans-serif;
            font-size: 2rem;
            font-weight: 600;
            letter-spacing: 3px;
            -webkit-text-stroke-width: 1px;
            stroke-width: 1px;
            -webkit-text-stroke-color: #ffffff;
            stroke: #ffffff;
          "
          class="text-center text-md-h2 text-uppercase"
        >
          {{ event.event.title }}
        </h1>
      </v-container>
      <v-sheet
        elevation="1"
        style="
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(50%);
        "
        :color="event.event.conference ? 'white' : 'black'"
        class="pa-10 custom-border-radius"
        width="500px"
        max-width="90%"
      >
        <v-row>
          <v-col class="d-flex justify-center flex-wrap" cols="12">
            <span
              style="color: var(--v-accent-base); font-size: 1.2rem"
              class="text-uppercase text-truncate font-weight-thin mr-1"
              v-for="(genre, index) of event.event.genres"
              :key="genre.id"
              >{{ genre.name
              }}<span v-if="index < event.event.genres.length - 1">,</span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            style="font-size: 1.5rem"
            class="d-flex justify-center font-weight-bold"
          >
            <span>w\</span
            ><span
              class="ml-1"
              v-for="(artist, index) of event.artists"
              :key="artist.id"
              >{{ artist.name
              }}<span v-if="index < event.artists.length - 1">,</span></span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon>mdi-map-marker</v-icon
            ><span
              class="text-center"
              v-if="event.event.venue"
              v-text="event.event.venue.name"
            ></span
            ><span v-else>TBA</span>
          </v-col>
          <v-col
            cols="5"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon>mdi-calendar</v-icon
            ><span class="text-center" v-text="date"></span>
          </v-col>
          <v-col
            cols="3"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon>mdi-clock</v-icon
            ><span class="text-center" v-text="time"></span>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <v-spacer style="height: 200px" />
    <v-container class="pb-10">
      <div v-text="event.event.description"></div>
      <div>
        <v-btn
          large
          prepend-icon="mdi-arrow-top-right"
          color="primary"
          v-if="event.event.ticketLink"
          :href="event.event.ticketLink"
          target="_blank"
          >Tickets kaufen</v-btn
        >
        <v-btn class="ml-2" large color="primary"
          ><v-icon>mdi-instagram</v-icon></v-btn
        >
        <v-btn class="ml-2" large color="primary"
          ><v-icon>mdi-facebook</v-icon></v-btn
        >
      </div>
    </v-container>
    <v-container>
      <h4 style="color: var(--v-accent-base)">{{ event.event.title }}</h4>
      <h3>SPEAKERS & ARTISTS ON THIS:</h3>
      <ArtistListPublic :artists="event.artists" />
    </v-container>
  </div>
</template>

<script>
import { getEventWithArtistsById } from "@/services/event.service";
import ArtistListPublic from "@/components/artist/ArtistListPublic";

export default {
  name: "SingleEventSite",
  components: { ArtistListPublic },
  props: {
    eventId: String,
  },
  computed: {
    date() {
      let moment = require("moment");
      return moment(this.event.event.dateTime).format("dddd MM[/]DD[/]YY");
    },
    time() {
      let moment = require("moment");
      return moment(this.event.event.dateTime).format("LT");
    },
  },
  data() {
    return {
      event: null,
    };
  },
  created() {
    if (this.eventId) this.getEvent();
  },
  methods: {
    getEvent: async function () {
      this.event = await getEventWithArtistsById(this.eventId);
      console.log(this.event);
    },
  },
};
</script>

<style scoped></style>
