<template>
<v-dialog v-model="dialog">
  <template #activator="{props}">
    <slot
        title="activator"
        name="activator"
        :props="props"
    >
      <v-btn
          v-bind="props"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </slot>
  </template>
  <v-sheet>
    <CreateEvent @success="updateEvent" @cancel="closeDialog" :event-to-edit="eventToEdit"/>
  </v-sheet>
</v-dialog>
</template>

<script>
import CreateEvent from "@/components/event/CreateEvent";
export default {
  name: "EditEvent",
  components: {CreateEvent},
  props:{
    event:Object
  },
  data(){
    return{
      dialog:false
    }
  },
  methods:{
    updateEvent(event){
      console.log("Edit",event);
      this.$emit("update",event);
      this.closeDialog();
    },
    closeDialog(){
      this.dialog=false
    }
  },
  computed:{
    eventToEdit(){
      let tmp = JSON.parse(JSON.stringify(this.event));
      //console.log("Genres",tmp.genres);
      tmp.genres = tmp.genres.map(x => x.id);
      if(tmp.venue) tmp.venue=tmp.venue.name;
      return tmp;
  }
  }
}
</script>

<style scoped>

</style>