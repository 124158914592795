<template>
  <v-container>
    <v-list class="bg-transparent">
      <ArtistListItemPublic
        v-for="artist of artistsSorted"
        :key="artist.id"
        :artist="artist"
      />
    </v-list>
  </v-container>
</template>

<script>
import ArtistListItemPublic from "@/components/artist/ArtistListItemPublic";
export default {
  name: "ArtistListPublic",
  components: { ArtistListItemPublic },
  props: {
    artists: Array,
  },
  computed: {
    artistsSorted() {
      return [...this.artists].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
  },
};
</script>

<style scoped></style>
