<template>
  <v-chip style="min-width: 300px" @click:close="deleteProfession" size="large" class="py-4" close>
    <v-text-field @change="updateProfession" v-model="professionC.role"
                  class="flex-grow-1"
                  bg-color="transparent"
                  min-width="100"
                  style="border-bottom-right-radius: 5px;border-top-right-radius: 5px;"
                  placeholder="Role" variant="flat" color="transparent" hide-details density="compact"></v-text-field>

    <v-autocomplete min-width="200" @keydown.enter="addCompany(companyToSearch)" item-text="name" item-title="name" item-value="id" return-object @change="updateProfession" :items="companies"
                    bg-color="transparent"
                    :search-input.sync="companyToSearch" v-model="professionC.company" class="ml-1 flex-grow-1"
                    style="border-bottom-left-radius: 5px;border-top-left-radius: 5px;"
                    prefix="@" placeholder="Company" color="transparent" variant="flat" hide-details density="compact">
      <template v-slot:no-data>
        <v-list-item link @click="addCompany(companyToSearch)">
          <span>Create Company: <span v-html="companyToSearch"></span></span>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-chip>
</template>
<script>
import ApiService from "@/services/api.service";
import {updateProfession} from "@/services/profession.servie";

export default {
  name: 'ProfessionItemUser',
  props: {
    profession:Object
  },
  data(){
    return{
      companies:[],
      companyToSearch:""
    }
  },
  created() {
    this.getAllCompanies();
  },
  computed:{
    professionC:{
      get(){
        return this.profession;
      },
      set(profession){
        this.$emit("updateProfession",profession);
      }
    },

  },
  methods:{
    async updateProfession() {
      let data = await updateProfession(this.professionC);
      this.professionC = data;
    },
    async deleteProfession() {
      await this.deleteProfession(this.professionC.id);
      this.$emit("delete", this.professionC);
    },
    addCompany(companyName){
      let self=this;
      ApiService.post("company/add",{id:null,name:companyName}).then(response => {
        self.companies.push(response.data);
        self.profession.companyId=response.data.id;
        self.$emit("updateProfession",self.profession);
      })
    },
    getAllCompanies(){
      ApiService.get("company/find/all").then(response => {
        this.companies=response.data;
      })
    }}

}
</script>