<template>
  <div class="transparent">
    <!-- <div
      id="program"
      style="
        height: 200px;
        background: linear-gradient(
            255deg,
            rgba(131, 52, 146, 1) 0%,
            rgba(255, 255, 255, 0) 65%
          ),
          black;
      "
      class="d-flex align-center py-0 py-md-15"
    >
      <v-container class="d-flex flex-wrap justify-center align-center">
        <h1
          style="
            width: 100%;
            color: #ffffff24;
            font-family: 'Neue Singular H', Sans-serif;
            font-size: 3rem;
            font-weight: 600;
            letter-spacing: 10px;
            -webkit-text-stroke-width: 1px;
            stroke-width: 1px;
            -webkit-text-stroke-color: #ffffff;
            stroke: #ffffff;
          "
          class="text-center text-md-h1 text-uppercase"
        >
          Program
        </h1>
      </v-container>
    </div> -->
    <ViewHeadline headlineText="Program" />
    <v-container class="py-5">
      <v-tabs
        id="filterTabs"
        v-model="whatToShow"
        grow
        class="mt-n3 mb-5"
        background-color="transparent"
      >
        <v-tab value="events" class="filter-chip" size="x-large">
          Events
        </v-tab>
        <v-tab value="artists" class="filter-chip" size="x-large">
          <span v-if="conferenceOrFestival.includes('festival')">Artists</span>
          <span v-if="conferenceOrFestival.length > 1" class="mx-1"> & </span>
          <span v-if="conferenceOrFestival.includes('conference')"
            >Speaker</span
          >
        </v-tab>
        <v-tab value="venues" class="filter-chip" size="x-large">
          Venues
        </v-tab>
      </v-tabs>
      <v-row class="">
        <v-col cols="10">
          <v-chip-group
            color="black"
            filter
            multiple
            mandatory
            v-model="conferenceOrFestival"
          >
            <v-chip
              rounded="lg"
              variant="tonal"
              value="conference"
              class="filter-chip"
              size="x-large"
              >Conference</v-chip
            >
            <v-chip
              rounded="lg"
              variant="tonal"
              value="festival"
              class="filter-chip"
              size="x-large"
              >Festival</v-chip
            >
          </v-chip-group>
        </v-col>

        <v-col cols="2" class="d-flex align-center justify-end">
          <v-btn
            v-if="$vuetify.display.mdAndUp"
            variant="tonal"
            rounded="lg"
            color="black"
            :class="{ 'filter-chip-active': selectedGenres.length > 0 }"
            @click="selectGenre = !selectGenre"
            size="large"
            prepend-icon="mdi-tune-vertical"
          >
            <span>Filter</span>
          </v-btn>
          <v-btn
            v-else
            variant="tonal"
            density="comfortable"
            color="black"
            :class="{ 'filter-chip-active': selectedGenres.length > 0 }"
            @click="selectGenre = !selectGenre"
            size="large"
            icon="mdi-tune-vertical"
          >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-window v-model="whatToShow">
      <v-window-item value="events">
        <v-list v-if="!loading" color="transparent" class="events">
          <div v-if="filteredEventsGroupedByDay.length <= 0">
            <h3 class="text-center">Nothing matches your search :(</h3>
          </div>

          <div v-else style="position: relative">
            <v-container
              v-for="day of filteredEventsGroupedByDay"
              :key="day.day"
            >
              <h3 class="text-uppercase text-color-accent mt-2">
                {{ dateToday(day.day) }}
              </h3>

              <div class="py-2" v-for="event of day.events" :key="event.id">
                <EventItem
                  :event="event.event"
                  :artists="event.artists"
                  @addToFavourites="(id) => event.event.savedByIds.push(id)"
                  @removeFromFavourites="
                    (id) =>
                      event.event.savedByIds.splice(
                        event.event.savedByIds.indexOf((x) => (x.id = id)),
                        1
                      )
                  "
                />
              </div>
            </v-container>
          </div>
        </v-list>

        <!-- Skeleton loader während des Ladens -->
        <div v-else>
          <v-container>
            <v-skeleton-loader
              v-for="n in 5"
              v-if="loading"
              class="mx-auto my-5"
              key="n"
              type="avatar, article"
            ></v-skeleton-loader>
          </v-container>
        </div>
      </v-window-item>
      <v-window-item value="artists">
        <ArtistListPublic :artists="filteredArtists" />
      </v-window-item>
      <!--Artists-->

      <v-window-item value="venues">
        <AllVenues />
      </v-window-item>
    </v-window>
    <v-dialog max-width="400px" v-model="selectGenre">
      <v-card class="pa-4">
        <h2 class="text-center mb-5">Filter</h2>
        <div>
          <h3>Days</h3>
          <v-chip-group filter column multiple v-model="selectedDays">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="black"
              v-for="day of allDays"
              :value="day"
              :key="day"
              >{{ weekday(day) }}</v-chip
            >
          </v-chip-group>
        </div>
        <div>
          <h3>Genres</h3>
          <v-chip-group filter column multiple v-model="selectedGenres">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="black"
              v-for="genre of allGenres"
              :value="genre.id"
              :key="genre.id"
              >{{ genre.name }}</v-chip
            >
          </v-chip-group>
        </div>

        <div>
          <h3>Conference Type</h3>
          <v-chip-group filter column multiple v-model="selectedCTypes">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="black"
              v-for="type of allConferenceTypes"
              :value="type"
              :key="type"
              >{{ type }}</v-chip
            >
          </v-chip-group>
        </div>
        <div>
          <h3>Conference Topic</h3>
          <v-chip-group filter column multiple v-model="selectedCTopics">
            <v-chip
              variant="flat"
              class="filter-chip"
              color="black"
              v-for="topic of allConferenceTopics"
              :value="topic"
              :key="topic"
              >{{ topic }}</v-chip
            >
          </v-chip-group>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import EventItem from "@/views/Event/EventItem";
import {
  getAllEventsGroupedByDateOfCurrentYear,
  getEvents,
} from "@/services/event.service";
import ArtistListPublic from "@/components/artist/ArtistListPublic.vue";
import AllVenues from "@/components/venue/AllVenues";
import { getAllVisibleArtists } from "@/services/artist.service";
import ViewHeadline from "@/components/helpers/ViewHeadline.vue";
export default {
  name: "EventSite",
  components: { AllVenues, ArtistListPublic, EventItem, ViewHeadline },
  data() {
    return {
      selectedGenres: [],
      selectGenre: false,
      whatToShow: "events",
      events: [],
      loading: false,
      searchQuery: "",
      conferenceOrFestival: ["festival", "conference"],
      selectedVenues: [],
      selectedDays: [],
      allArtists: [],
      selectedCTopics: [],
      selectedCTypes: [],
    };
  },
  async created() {
    this.loading = true;
    try {
      await this.getEventsGroupedByDay();
      await this.getAllArtists();
      this.selectedGenres = JSON.parse(
        JSON.stringify(this.allGenres.map((x) => x.id))
      );
      this.selectedCTypes = JSON.parse(JSON.stringify(this.allConferenceTypes));
      this.selectedCTopics = JSON.parse(
        JSON.stringify(this.allConferenceTopics)
      );
      this.selectedDays = JSON.parse(JSON.stringify(this.allDays));
    } catch (error) {
      console.error("Fehler beim Laden der Daten:", error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    allDays() {
      return this.filteredEventsGroupedByDay.map((x) => x.day);
    },
    allConferenceTypes() {
      let uniqueTypes = new Array();

      this.eventsGroupedByDay.forEach((day) => {
        day.events.forEach((event) => {
          let type = event.event.conferenceType;
          if (type && uniqueTypes.findIndex((x) => x === type) === -1)
            uniqueTypes.push(type);
        });
      });
      return uniqueTypes;
    },
    allConferenceTopics() {
      let uniqueTopics = new Array();

      this.eventsGroupedByDay.forEach((day) => {
        day.events.forEach((event) => {
          event.event.conferenceTopics.forEach((topic) => {
            if (uniqueTopics.findIndex((x) => x === topic) === -1)
              uniqueTopics.push(topic);
          });
        });
      });
      return uniqueTopics;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    filteredArtists() {
      let result = JSON.parse(JSON.stringify(this.allArtists));
      if (!this.conferenceOrFestival.includes("festival"))
        result = result.filter((x) => !x.festival);
      if (!this.conferenceOrFestival.includes("conference"))
        result = result.filter((x) => !x.conference);
      result = result.filter(
        (x) =>
          x.genre.findIndex((g) => this.selectedGenres.includes(g.id)) != -1 ||
          x.genre.length <= 0
      );
      return result;
    },
    filteredEventsGroupedByDay() {
      let result = JSON.parse(
        JSON.stringify(this.$store.state.events.eventsGroupedByDay)
      );
      for (let day of result) {
        if (!this.selectedDays.includes(day.day)) day.events = [];
        let filteredEvents = day.events;
        if (!this.conferenceOrFestival.includes("festival"))
          filteredEvents = filteredEvents.filter((x) => x.event.conference);
        if (!this.conferenceOrFestival.includes("conference"))
          filteredEvents = filteredEvents.filter((x) => !x.event.conference);
        filteredEvents = filteredEvents.filter(
          (x) =>
            x.event.genres.findIndex((g) =>
              this.selectedGenres.includes(g.id)
            ) != -1 || x.event.genres.length <= 0
        );
        filteredEvents = filteredEvents.filter(
          (x) =>
            x.event.conferenceTopics.findIndex((t) =>
              this.selectedCTopics.includes(t)
            ) != -1 || x.event.conferenceTopics.length <= 0
        );
        filteredEvents = filteredEvents.filter(
          (x) =>
            this.selectedCTypes.includes(x.event.conferenceType) ||
            x.event.conferenceType == null
        );
        day.events = filteredEvents;
      }
      return result.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.day) - new Date(b.day);
      });
    },

    allGenres() {
      let uniqueGenres = new Array();

      this.eventsGroupedByDay.forEach((day) => {
        day.events.forEach((event) => {
          event.event.genres.forEach((genre) => {
            if (uniqueGenres.findIndex((x) => x.id === genre.id) === -1)
              uniqueGenres.push(genre);
          });
        });
      });

      this.allArtists.forEach((artist) => {
        artist.genre.forEach((genre) => {
          if (uniqueGenres.findIndex((x) => x.id === genre.id) === -1)
            uniqueGenres.push(genre);
        });
      });
      return uniqueGenres;
    },
    allVenues() {
      let uniqueVenues = new Set();
      this.filteredEventsGroupedByDay.forEach((day) => {
        day.events.forEach((event) => {
          console.log("Venua", event.event.venue);
          if (event.event.venue) uniqueVenues.add(event.event.venue);
        });
      });
      return uniqueVenues;
    },
    eventsGroupedByDay() {
      return this.$store.state.events.eventsGroupedByDay;
    },
    groupEventsByDate() {
      let moment = require("moment"); // require
      return this.filteredEvents.reduce(function (groups, item) {
        var name = moment(item.event.dateTime).format("dddd[,] DD[.]MM");
        var group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
      }, {});
    },
  },
  methods: {
    async getAllArtists() {
      let response = await getAllVisibleArtists();
      this.allArtists = response;
    },
    weekday(day) {
      let moment = require("moment");
      return moment(day).format("dddd");
    },
    filterEventsOfDay(events) {
      return events;
    },
    dateToday(date) {
      let moment = require("moment");
      return moment(date).format("DD[.]MM dddd");
    },
    loadMoreEventsByDate(date) {
      this.$store.dispatch("events/loadAllEventsOfDate", date);
    },
    async getEventsGroupedByDay() {
      try {
        const response = await getAllEventsGroupedByDateOfCurrentYear();
        this.$store.state.events.page++;
        this.$store.dispatch("events/setEventsGroupedByDate", response);
      } catch (error) {
        console.error("Error loading events:", error);
      } finally {
      }
    },
    getEventsUnauthorized() {
      let self = this;
      ApiService.getUnauthorized("event/inkognitoEvents").then((response) => {
        self.$store.dispatch(
          "events/setEvents",
          response.data.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.eventDatumTime) - new Date(b.eventDatumTime);
          })
        );
      });
    },
  },
};
</script>

<style scoped>
.filter-chip {
  padding: 1em 2em;
  opacity: 1;
}
.filter-chip-active {
  opacity: 1 !important;
}
.tab-active .filter-chip {
  opacity: 1;
}
</style>
