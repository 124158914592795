<script>
import ProductOverview from "@/components/cart/checkout/ProductOverview.vue";
import TicketConsumerForm from "@/components/cart/checkout/TicketConsumerForm.vue";

export default {
  name: "CartCheckout",
  components: { TicketConsumerForm, ProductOverview },
  data() {
    return {
      loading: false,
      consumer: null,
      isFormValid: false,
      vouchers: [],
      voucherErrors: [],
    };
  },
  methods: {
    updateConsumer(user) {
      user.ip = null;
      this.consumer = user;
    },
    async generateBill() {
      this.loading = true;
      let generateBillRequest = {};
      let products = [];
      this.cart.forEach((product) => {
        products.push({
          product: product.product.uid,
          quantity: product.amount,
        });
      });
      generateBillRequest.products = products;
      generateBillRequest.vouchers = this.vouchers;
      generateBillRequest.consumer = this.consumer;
      try{
       await this.$store.dispatch(
            "checkout/generateBill",
            generateBillRequest
        );
        await this.$router.push("/checkout/payment");
      }catch (error) {
        console.log("error",error);
        this.voucherErrors.push(error?.response?.data?.message);
      }finally {
        this.loading = false;
      }
    },
    handleFormValidation({ isValid, user }) {
      this.isFormValid = isValid;
      this.consumer = user;
    },
  },
  computed: {
    cart() {
      return this.$store.state.cart.cart;
    },
  },
};
</script>

<template>
  <v-container class="checkout-container mt-md-7">
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="7">
        <div class="px-3">
          <TicketConsumerForm
            address
            gender
            birth-date
            @updateUser="updateConsumer"
            @formValidation="handleFormValidation"
          >
          </TicketConsumerForm>
        </div>
      </v-col>

      <v-col cols="12" md="5" lg="4">
        <h2>Your Order</h2>
        <v-spacer class="my-5"></v-spacer>
        <ProductOverview />
        <v-spacer class="my-8"></v-spacer>
        <v-combobox append-icon="" class="ticket-form" :error-messages="voucherErrors" multiple solo chips deletable-chips label="Vouchers" v-model="vouchers"></v-combobox>
        <v-btn
          :loading="loading"
          :disabled="!isFormValid"
          color="accent"
          @click="generateBill"
          block
          >Continue to Payment</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.checkout-container {
  height: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 120px; /* Zusätzlicher Platz für die mobile Navigation */
}
</style>
