<template>
  <div
    id="program"
    style="
      height: 200px;
      background: linear-gradient(255deg, rgba(131, 52, 146, 1) 0%, black 80vw),
        black;
      overflow: hidden;
    "
    class="d-flex align-center py-0 ml-md-n8 py-md-15"
  >
    <div class="headline-container">
      <h1
        class="scrolling-headline text-uppercase"
        :style="{
          '--headline-content': `'${headlineText} // '`,
        }"
      >
        <span class="d-none d-md-block">{{ generateRepeatedText() }}</span>
        <span class="d-md-none text-center">{{ headlineText }}</span>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headlineText: {
      type: String,
      required: true,
    },
  },
  methods: {
    generateRepeatedText() {
      return (this.headlineText + " // ").repeat(20);
    },
  },
};
</script>

<style scoped>
.headline-container {
  width: 100%;
  overflow: hidden;
}

.scrolling-headline {
  white-space: nowrap;
  color: #ffffff24;
  font-family: "Neue Singular H", Sans-serif;
  font-size: 3rem;
  font-weight: 600;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  stroke: #ffffff;
  stroke-width: 1px;
}

.d-md-none {
  display: block;
  width: 100%;
  text-align: center;
  white-space: normal;
}
</style>
