<script>
export default {
  name: "ProductItem",
  props: {
    cartResponse: null,
  },
  data() {
    return {};
  },
  computed: {
    amount() {
      return this.cartResponse.amount;
    },
    product() {
      return this.cartResponse.product;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<template>
  <div class="d-flex justify-space-between">
    <span>{{ amount }} x {{ product.name }}</span>
    <span class="price">{{ product.price }} {{ product.currency }}</span>
  </div>
</template>

<style scoped></style>
