<template>
  <v-container v-if="bill" class="checkout-container mt-md-7">
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="7">
        <div>
          <div v-if="bill.needsPayment">
            <h2>Payment</h2>
            <h3>Select Payment Method</h3>
            <SelectPaymentMethod
              @updatePaymentMethod="updatePaymentMethod"
              :methods="bill.availablePaymentMethods"
            />
          </div>
          <v-divider class="mb-2"></v-divider>
          <div class="d-flex justify-space-between px-2">
            <h3>Shipping</h3>
            <div>ETicket</div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <div>
          <h2>Your Order</h2>
          <v-list-item
            v-for="product of bill.products"
            :title="product.name"
            :key="product.uid"
          >
            <template #append>
              <span v-text="product.totalPrice.amount"></span
              ><span class="ml-1" v-text="product.totalPrice.currency"></span>
            </template>
          </v-list-item>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between font-weight-bold px-4 my-3">
            <div>Total</div>
            <div>
              <span v-text="bill.grandTotal.amount"></span>
              <span v-text="' ' + bill.grandTotal.currency"></span>
            </div>
          </div>

          <p class="mt-2">
            By clicking "Checkout" you agree to the terms and conditions of this
            purchase. Paylogic
          </p>

          <a
            href="https://go.paylogic.com/de/gmbh/terms-and-conditions"
            target="_blank"
            >General terms and conditions of use</a
          ><br />

          <a
            href="https://go.paylogic.com/de/privacy-policy-seetickets"
            target="_blank"
            >Privacy policy</a
          >

          <v-btn
            block
            class="mt-7"
            :disabled="
              !request || (bill.needsPayment && !request.payment_method)
            "
            color="accent"
            @click="checkout"
            :loading="loading"
            >Order now (subject to charge)</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <h2>Error loading Details</h2>
    <p>Please try again</p>
  </v-container>
</template>

<script>
import SelectPaymentMethod from "@/components/order/payment/SelectPaymentMethod";
export default {
  name: "BillPayment",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    bill() {
      return this.$store.state.checkout.bill;
    },
    request() {
      return this.bill.placeOrderRequest;
    },
  },
  created() {
    if (!this.bill) {
      this.$router.push("/checkout");
    }
  },
  methods: {
    updatePaymentMethod(value) {
      this.$store.dispatch("checkout/updatePaymentMethod", value);
    },
    async checkout() {
      this.loading = true;
      const redirectTo = await this.$store.dispatch(
        "checkout/checkout",
        this.request
      );
      console.log("Redirecting to", redirectTo);
      this.$store.dispatch("cart/emptyCart");
      if (redirectTo.needsPayment)
        await this.$router.push("/checkout/payment/pay?l=" + redirectTo.link);
      else await this.$router.push(redirectTo.link);
      this.loading = false;
    },
  },
  components: { SelectPaymentMethod },
};
</script>

<style scoped>
.checkout-container {
  height: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 120px; /* Zusätzlicher Platz für die mobile Navigation */
}
</style>
