<template>
  <v-card-text>
    <div class="cart-container">
      <h3 class="ma-5" v-if="cart.length <= 0">Your Cart is empty</h3>
      <CartItem
        class="my-5"
        v-for="product of cart"
        :cart-response="product"
        :key="product.uid"
      />
    </div>
  </v-card-text>
</template>

<script>
import CartItem from "@/components/cart/CartItem";
export default {
  name: "UserCart",
  components: { CartItem },
  watch: {
    totalItems() {
      this.$emit("amount", this.totalItems);
    },
  },
  computed: {
    totalItems() {
      return this.cart.length;
    },
    total() {
      let Euro = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      let response = 0;
      for (let item of this.cart) {
        response += item.amount * item.product.price;
      }
      return Euro.format(response);
    },
    cart() {
      return this.$store.state.cart.cart;
    },
  },
};
</script>

<style scoped>
.cart-container {
  padding-bottom: 16px;
}
</style>
