<template>
  <v-chip-group column class="w-100">
    <ProfessionItem
      @updateProfession="updateProfessionfromChild"
      v-for="profession of currentUser.professions"
      :key="profession.id"
      :profession="profession"
    />
    <v-btn
      class="mx-auto my-5"
      :block="$vuetify.display.smAndDown"
      color="primary"
      @click="addNewProfession"
      >add another Profession*</v-btn
    >

    <small class="position-absolute bottom-0 right-0"
      >*Profession could be a Project you're working on/ a job</small
    >
  </v-chip-group>
</template>
<script>
import ApiService from "@/services/api.service";
import { updateUser } from "@/services/user.service";
import Notification from "@/models/notification";
import ProfessionItem from "@/views/Welcome/ProfessionItem";

export default {
  name: "ProfessionsComponent",
  components: { ProfessionItem },
  props: {},
  data() {
    return {
      companies: [],
      companyToSearch: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (
      !this.currentUser.professions ||
      this.currentUser.professions.length <= 0
    ) {
      this.addNewProfession();
    }
  },
  methods: {
    addNewProfession() {
      let self = this;
      ApiService.get("user/profession/add").then((response) => {
        if (response.data) {
          updateUser(response.data);
        } else {
          self.$store.dispatch(
            "notificationsStore/add",
            new Notification(
              "error",
              "Cannot add Profession",
              "",
              "Please try again"
            )
          );
        }
      });
    },
    updateProfessionfromChild(profession) {
      this.currentUser.professions[
        this.currentUser.professions.indexOf((p) => p.id === profession.id)
      ] = profession;
      updateUser(this.currentUser);
    },
  },
};
</script>
