import ApiService from "@/services/api.service";
import Artist from "@/models/Artist";

export async function toggleWebsiteVisibilityArtist(id,state){

    let response = await ApiService.post("user/website/visible/toggle",{id:id,state:state});
    if(response.data) return response.data;
}

export async function getAllArtistUsers(){
    let response = await ApiService.get("user/artist/all");
    if(response.data) return response.data;
}
export async function getAllArtistsByPage(page){
    let response = await ApiService.get("user/artist/simple/all/"+page);
    if(response.data) return response.data;
}
export async function editArtist(editUserRequest){
    let response = await ApiService.post("artist/edit",editUserRequest);
    if(response.data) return response.data;
}
export async function getAllArtistsSimple(){
    let response = await ApiService.get("user/artist/simple/all");
    if(response.data) return response.data;
}

export async function getMyArtistsSimple(){
    let response = await ApiService.get("artist/get/my");
    if(response.data) return response.data;
}
export async function getMyArtistsSimpleByPage(filter,page){
    let response = await ApiService.post("artist/get/my/"+page,filter);
    if(response.data) return response.data;
}

export async function shareArtist(artistId,ids){
    let response = await ApiService.post("artist/share/"+artistId,{ids: ids});
    if(response.data) return response.data;
}

export async function getAllArtistsByIdIn(ids){
    let response = await ApiService.post("artist/get/list",{ids:ids});
    if(response.data) return response.data;
}
export async function getAllArtists(){
    let response = await ApiService.get("artist/get/all");
    if(response.data) return response.data;
}
export async function getAllVisibleArtists(){
    let response = await ApiService.get("artist/get/all/visible");
    if(response.data) return response.data;
}
export async function getAllArtistsByEvent(eventId){
    let response = await ApiService.get("artist/get/all/"+eventId);
    if(response.data) return response.data;
}
export async function convertUserToArtist(user){
    let artist = new Artist();
    artist.id=user.id;
    if(user.profilePic) artist.profilePicture=user.profilePic.file
    artist.name= user.name;
    artist.professions= [];
    artist.visibleOnWebsite= user.visibleOnWebsite;
    return artist;
}