<template>
  <v-bottom-navigation
    v-if="$vuetify.display.smAndDown"
    app
    height="65"
    mode="shift"
    grow
    color="black"
    background-color="black"
  >
    <v-progress-linear
      v-if="$route.name === 'Welcome'"
      style="position: absolute; left: 0; top: 0; width: 100%; z-index: 99999"
      color="accent"
      v-model="progress"
    ></v-progress-linear>

    <v-btn :value="'feed'" to="/feed" variant="plain" class="rounded-t">
      <v-icon>mdi-home-variant</v-icon>
      <span class="text-caption">Feed</span>
    </v-btn>

    <v-btn :value="'lobby'" to="/lobby" variant="plain" class="rounded-t">
      <v-icon>mdi-compass</v-icon>
      <span class="text-caption">Lobby</span>
    </v-btn>

    <v-btn
      :value="'create'"
      color="accent"
      @click="createFeedDialog = true"
      class="rounded accent-color"
    >
      <v-icon>mdi-plus</v-icon>
      <span class="text-caption">Create</span>
    </v-btn>

    <v-btn
      :value="'favourites'"
      to="/favourites"
      variant="plain"
      class="rounded-t"
    >
      <v-icon>mdi-bookmark-multiple</v-icon>
      <span class="text-caption">Saved</span>
    </v-btn>

    <v-btn :value="'events'" to="/events" variant="plain" class="rounded-t">
      <v-icon>mdi-timetable</v-icon>
      <span class="text-caption">Program</span>
    </v-btn>
  </v-bottom-navigation>

  <v-dialog v-model="createFeedDialog" fullscreen>
    <CreateFeedItem @toggleDialog="toggleCreateFeedDialog" />
  </v-dialog>
</template>

<script>
import CreateFeedItem from "@/components/feed/CreateFeedItem";
export default {
  name: "MobilebarBottom",
  components: { CreateFeedItem },
  methods: {
    handleLogout: function () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    toggleCreateFeedDialog(value) {
      this.createFeedDialog = value;
    },
  },
  data() {
    return {
      createFeedDialog: false,
    };
  },
  computed: {
    page: {
      get() {
        return this.$store.state.app.welcome.page;
      },
      set(value) {
        this.$store.state.app.welcome.page = value;
      },
    },
    pages() {
      return this.$store.state.app.welcome.pages;
    },
    progress() {
      return (this.page / this.pages) * 100;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    chatPartners() {
      return this.$store.state.chat.currentChatPartners;
    },
    unreadMessages() {
      const sum = this.chatPartners.reduce((accumulator, object) => {
        return accumulator + object.newMessages;
      }, 0);
      return sum;
    },
  },
};
</script>

<style scoped>
.accent-color {
  color: #db54fd !important;
}
</style>
