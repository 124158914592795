<template>
  <v-card flat rounded="lg" color="offwhite" class="my-5">
    <template #prepend>
      <v-avatar tile size="75" rounded="lg" class="mr-2">
        <v-img
          :src="
            artist.profilePicture
              ? 'data:image/png;base64,' + artist.profilePicture
              : require('../../assets/Placeholder-Profile-819x1024.webp')
          "
        />
      </v-avatar>
    </template>
    <template #title>
      <p
        class="font-weight-bold"
        style="font-size: 1.3rem"
        v-html="artist.name"
      ></p>
    </template>
    <template #subtitle>
      <div>
        <span v-for="(profession, index) of artist.professions" :key="index">
          <span class="mr-1" v-text="profession.company?.name"></span>
          <span v-if="index < artist.professions.length - 1">|</span>
        </span>
      </div>
    </template>
  </v-card>
</template>

<script>
import Artist from "@/models/Artist";

export default {
  name: "ArtistListItemPublic",
  props: {
    artist: Artist,
  },
};
</script>

<style scoped></style>
