import Notification from "@/models/notification";
import store from "@/store";
import ApiService from "@/services/api.service";
import {getBillNew} from "@/services/checkout.service";

export const checkout={
    namespaced: true,
    state:{
        bill:null,
        paymentMethod:null,
        order:null
    },
    actions:{
      async generateBill({ commit },billRequest) {
          if(!billRequest || !billRequest.products || billRequest.products.length===0){
              console.log("Cart is empty");
              await store.dispatch("notificationsStore/add", new Notification("error", "Error validation your order. Please try again.", false));
              return false;
          }
          let response=null;
          try{
              response = await getBillNew(billRequest);
          }catch(e){
              console.log("Error j",e);
              throw e;
          }

          commit("setBill",response.data);
          console.log("Bill",response);
          return response;
      },
        updatePaymentMethod({commit},paymentMethod){
          commit('updatePaymentMethod',paymentMethod);
        },
        async checkout({commit},productsAndPaymentMethod){
                const response =  await ApiService.post("ticket/order/place/new",productsAndPaymentMethod);
                if(response.status!==200){
                    return {
                        needsPayment:false,
                        link:"/payment/done?order_state=error"
                    };
                }
                const order = response.data;
                commit("setOrder",order);
                if(order && order.paymentLink){
                    return {
                        needsPayment:true,
                        link: order.paymentLink
                    };
                }else{
                    let email = order?.consumer?.email;
                    if(email){
                        return{
                            link:"/payment/done?order_state=completed&email="+email+"&order_id="+order.uid,
                            needsPayment:false
                        }
                    }
                    return{
                        link:"/payment/done?order_state=completed&order_id="+order.uid,
                        needsPayment:false
                    }
                }
        }
    },
    mutations:{
        setOrder(state,order){
            state.order = order;
        },
        setBill(state,bill){
            state.bill = bill;
        },
        updatePaymentMethod(state, paymentMethod) {
            state.bill.placeOrderRequest.payment_method = paymentMethod;
        }
    }
}