<template>
  <v-card
    :dark="!event.conference"
    :color="event.conference ? 'offwhite' : 'primary'"
    :to="'/event/' + event.id"
    style="position: relative"
    flat
    class="my-2 pa-8 custom-border-radius"
  >
    <v-row>
      <v-col cols="2" class="hidden-sm-and-down">
        <div class="d-flex justify-start">
          <v-img
            style="margin-right: -50px"
            class="hvr-front rounded-circle"
            max-width="75px"
            width="75px"
            height="75px"
            v-for="artist of artists"
            :key="artist.id"
            :src="
              artist.profilePicture
                ? 'data:image/png;base64,' + artist.profilePicture
                : require('../../assets/Placeholder-Profile-819x1024.webp')
            "
          />
        </div>
      </v-col>
      <v-col cols="12" md="10" class="pt-1">
        <v-list-item class="pt-0">
          <v-list-item-title
            class="text-uppercase font-weight-bold text-wrap"
            :class="$vuetify.display.mdAndUp ? 'desktop-title' : 'mobile-title'"
            v-html="event.title"
          ></v-list-item-title>
          <v-list-item-subtitle class="pt-1">
            <span>/w </span>
            <span
              class="text-uppercase"
              v-for="(artist, $index) of artists"
              :key="$index"
            >
              {{ artist.name
              }}<span v-if="$index < artists.length - 1">, </span>
            </span>
          </v-list-item-subtitle>
        </v-list-item>
        <div class="d-flex ga-1 justify-space-between flex-wrap ml-3 mt-10">
          <div class="d-flex ga-1 flex-wrap">
            <v-chip
              v-if="event.venue"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
              variant="flat"
              :color="event.conference ? 'white' : 'primary'"
              prepend-icon="mdi-map-marker"
              >{{ event.venue.name }}</v-chip
            ><v-chip
              v-if="event.dateTime"
              prepend-icon="mdi-clock"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
              variant="flat"
              :color="event.conference ? 'white' : 'primary'"
              >{{ duration }}</v-chip
            ><v-chip
              v-if="event.conferenceType"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
              variant="flat"
              :color="event.conference ? 'white' : 'primary'"
              >{{ event.conferenceType }}</v-chip
            >
          </div>
          <v-chip
            v-if="event.ticketLink"
            prepend-icon="mdi-arrow-top-right"
            :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
            variant="flat"
            :color="event.conference ? 'white' : 'primary'"
            :href="event.ticketLink"
            target="_blank"
            @click.stop
            >Tickets</v-chip
          >
        </div>
        <!-- <div class="d-flex justify-space-between">
          <v-row>
            <v-col cols="6" class="col-12 col-md-6">
              <div class="d-flex align-center">
                <v-icon small>mdi-map-marker</v-icon>
                <span v-html="event.venue?.name"></span>
              </div>
            </v-col>
            <v-col
              v-if="event.savedByIds.length > 0"
              cols="4"
              class="col-12 col-md-4"
            >
              <span class="d-flex flex-nowrap">
                <div v-if="currentUser" style="z-index: 99">
                  <v-menu
                    v-if="event.savedByIds.length > 9"
                    bottom
                    max-height="200px"
                    origin="center center"
                    transition="slide-y-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="align-self-center pl-0"
                        plain
                        text
                        x-small
                        >+{{ event.savedByIds.length - 9 }} more</v-btn
                      >
                    </template>
                  </v-menu>
                </div>
                <div v-else>
                  <span v-html="event.savedByIds.length"></span>
                  <span>people participating</span>
                </div>
              </span>
            </v-col>
            <v-col v-else cols="4" class="col-12 col-md-4 hidden-sm-and-down">
            </v-col>

            <div
              class="px-5 py-2"
              style="
                position: absolute;
                bottom: 0;
                right: 0;
                font-weight: lighter;
              "
            >
              <small v-html="start"></small>
            </div>
          </v-row>
        </div> -->
      </v-col>
    </v-row>
    <div
      style="position: absolute; top: 5px; right: 5px"
      class="d-flex align-start"
    >
      <span v-if="!currentUser">
        <v-btn variant="text" @click.prevent="loginToSaveFavourites" icon
          ><v-icon>mdi-bookmark-outline</v-icon></v-btn
        >
      </span>
      <v-btn
        variant="text"
        v-else-if="currentUser.favourites?.includes(event.id)"
        icon
        @click.prevent="removeFavourite(event.id)"
        ><v-icon>mdi-bookmark</v-icon></v-btn
      >
      <v-btn
        variant="text"
        v-else
        icon
        @click.prevent="addToFavourites(event.id)"
        ><v-icon>mdi-bookmark-outline</v-icon></v-btn
      >
    </div>
  </v-card>
</template>

<script>
import ApiService from "@/services/api.service";
import moment from "moment";
import Notification from "@/models/notification";
import Event from "@/models/event";
export default {
  name: "EventItem",

  props: {
    artists: Array,
    event: Event,
  },
  data() {
    return {
      backgroundOpacity: 0.12,
    };
  },
  computed: {
    isConference() {
      return this.event.conference;
    },
    duration() {
      moment.locale("en");
      if (this.event.end) {
        let start = moment(this.event.dateTime);
        let end = moment(this.event.end);
        let duration = moment.duration(end.diff(start));
        return `${start.format("HH:mm")} - ${end.format("HH:mm")} (${Math.floor(
          duration.asHours()
        )}h ${duration.minutes()}min)`;
      }
      return moment(this.event.dateTime).format("HH:mm");
    },
    ticketLink() {
      return this.event.ticketLink;
    },
    eventDay() {
      moment.locale("en");
      return moment(this.event.dateTime).format("dddd");
    },
    currentUser: {
      get() {
        return this.$store.state.auth.user;
      },
      set(data) {
        this.$store.state.auth.user = data;
      },
    },
  },
  watch: {
    "$vuetify.theme.dark"() {
      if (this.$vuetify.theme.dark) this.backgroundOpacity = 0.02;
      else this.backgroundOpacity = 0.12;
    },
  },

  methods: {
    addToFavourites(id) {
      if (!this.currentUser) return;

      const user = { ...this.currentUser };
      if (!user.favourites) {
        user.favourites = [];
      }
      console.log("User: ", user);
      if (!user.favourites?.includes(id)) {
        user.favourites.push(id);
        this.updateUser();

        ApiService.get(`event/favourites/add/${this.event.id}`);
        this.$emit("addToFavourites", user.id);
      }
    },
    removeFavourite(id) {
      if (this.currentUser.favourites?.includes(id)) {
        this.currentUser.favourites.splice(
          this.currentUser.favourites.indexOf(id),
          1
        );
        this.updateUser();
        ApiService.get("event/favourites/remove/" + this.event.id);
        this.$emit("removeFromFavourites", this.currentUser.id);
        //this.event.savedByIds.splice(this.event.savedByIds.indexOf(x=> x.id=this.currentUser.is),1);
      }
    },
    loginToSaveFavourites() {
      this.$store.dispatch(
        "notificationsStore/add",
        new Notification(
          "warning",
          "Login to save Favourites.",
          false,
          null,
          "/login",
          "Login now"
        )
      );
    },
    async updateUser() {
      try {
        const response = await ApiService.post("user/update", this.currentUser);
        const user = JSON.parse(localStorage.getItem("user"));
        const merged = { ...user, ...response.data };
        await this.$store.dispatch("auth/updateLocalUser", merged);
        await this.$store.dispatch("loading/success");
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Benutzers:", error);
      }
    },
  },
};
</script>

<style scoped>
.event-box-overlay {
  background-position: 0, 50%;
  background-image: url("../../assets/wellen-2.webp");
  background-size: cover;
  opacity: 0.12;
}
.event-box {
  border-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 2px 2px 2px 2px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-color: #bcbcbc;
  border-top-color: rgb(188, 188, 188);
  border-right-color: rgb(188, 188, 188);
  border-bottom-color: rgb(188, 188, 188);
  border-left-color: rgb(188, 188, 188);
}
.desktop-title {
  font-size: 1.6rem;
}
.mobile-title {
  font-size: 1.1rem;
}
</style>
