<template>
  <v-app-bar app order="1" height="65" class="app-header" width="100%">
    <div
      style="width: 100%; max-width: 1200px"
      class="mx-auto d-flex justify-space-between align-center"
    >
      <v-btn icon @click="$router.back()">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <div style="height: 50px; width: 50px">
        <v-img
          height="45px"
          width="45px"
          :src="require('../../assets/Logo_w_transparent.png')"
        ></v-img>
      </div>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down mr-10">
        <v-btn
          class="menu-item text-uppercase"
          :disabled="$route.name === 'Feed'"
          text
          to="/feed"
          >Feed</v-btn
        >
        <v-btn
          class="menu-item text-uppercase"
          :disabled="$route.name === 'Lobby'"
          text
          to="/lobby"
          >Lobby</v-btn
        >
        <v-btn
          class="menu-item text-uppercase"
          :disabled="$route.name === 'Events'"
          text
          to="/events"
          >Festival Program</v-btn
        >
        <v-btn
          class="menu-item text-uppercase"
          :disabled="$route.name === 'My Timetable'"
          text
          to="/favourites"
          >My timetable</v-btn
        >
      </div>

      <v-btn
        class="font-weight-bold hidden-sm-and-down mr-2"
        size="large"
        variant="flat"
        color="white"
        to="/shop"
        >Tickets</v-btn
      >
      <CartButtom />
      <div style="z-index: 10">
        <v-btn
          :disabled="$route.name === 'Profile'"
          @click.prevent="$router.push('/profile')"
          v-if="!currentUser"
          icon
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn
          :disabled="$route.name === 'Profile'"
          @click.prevent="$router.push('/profile')"
          v-else
          icon
        >
          <v-avatar size="35px">
            <v-img
              :src="
                currentUser.profilePic
                  ? 'data:image/png;base64,' + currentUser.profilePic.file
                  : require('../../assets/Placeholder-Profile-819x1024.webp')
              "
            ></v-img>
          </v-avatar>
        </v-btn>
      </div>
      <v-app-bar-nav-icon
        color="white"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
    </div>
  </v-app-bar>
  <v-progress-linear
    style="position: fixed; z-index: 9999; left: 0; top: 0"
    v-if="
      $store.state.loading.loadingState || $store.state.loading.success.state
    "
    :indeterminate="!$store.state.loading.success.state"
    class="animate__animated"
    :class="{
      animate__fadeOutUp: $store.state.loading.success.animateOut,
      'bg-success': $store.state.loading.success.state == 'success',
      'bg-error': $store.state.loading.success.state == 'error',
    }"
    color="accent"
  ></v-progress-linear>
  <v-dialog v-model="drawer" app fullscreen>
    <OffCanvasMenu @close="() => (this.drawer = false)" />
  </v-dialog>
</template>

<script>
import OffCanvasMenu from "@/views/Navigation/OffCanvasMenu";
import CartButtom from "@/components/cart/CartButtom.vue";
export default {
  name: "TopbarSide",
  components: { CartButtom, OffCanvasMenu },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    canAccessManagement() {
      return (
        this.currentUser.roles.includes("ROLE_ADMIN") ||
        this.currentUser.roles.includes("ROLE_PROMOTER") ||
        this.currentUser.roles.includes("ROLE_CONF_MANAGER")
      );
    },
    chatPartners() {
      return this.$store.state.chat.currentChatPartners;
    },
    unreadMessages() {
      const sum = this.chatPartners.reduce((accumulator, object) => {
        return accumulator + object.newMessages;
      }, 0);
      return sum;
    },
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    handleLogout: function () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    gotTo(target) {
      this.$router.push("/" + target);
    },
  },
};
</script>

<style scoped>
.app-header {
  border-bottom: 1px solid rgba(235, 235, 235, 0) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  background: linear-gradient(
    255deg,
    rgba(131, 52, 146, 1) 0%,
    black 80vw
  ) !important;
}
.menu-item {
  font-size: 0.9rem !important;
  font-weight: 700 !important;
  font-family: "Neue-Singular-H", sans-serif !important;
  color: white !important;
}
</style>
