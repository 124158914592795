<template>
  <div
    class="h-100 bg-primary fill-height"
    style="
      min-height: 100vh;
      margin: 0;
      max-width: 100%;
      background: linear-gradient(270deg, rgba(131, 52, 146, 1) 0%, black 80vw);
    "
  >
    <div class="headline-container py-16 ms-n8">
      <h1
        class="scrolling-headline text-uppercase"
        :style="{
          '--headline-content': 'Ticketshop // ',
        }"
      >
        <span class="d-none d-md-block">{{
          generateRepeatedText("Ticketshop")
        }}</span>
        <span class="d-md-none text-center">Ticketshop</span>
      </h1>
    </div>
    <v-container>
      <v-row v-if="!loading" justify="center">
        <v-col v-for="product in tickets" :key="product.uid" cols="12">
          <SingleTicketToBuy :ticket="product" />
        </v-col>
      </v-row>
      <v-row v-else class="mx-auto my-5" v-for="n in 5" :key="n">
        <v-card flat rounded="lg" color="offwhite" class="pa-5" width="100%">
          <v-row>
            <v-col cols="9" class="pa-4">
              <v-skeleton-loader
                color="transparent"
                type="heading"
                class="mb-4"
                width="300px"
              ></v-skeleton-loader>
              <v-skeleton-loader
                color="transparent"
                type="paragraph"
                class="mb-2"
                width="350px"
              ></v-skeleton-loader>

              <v-skeleton-loader
                color="transparent"
                :type="$vuetify.display.mdAndUp ? 'button' : 'button'"
                width="300px"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="pa-4 d-flex justify-center align-center">
              <v-skeleton-loader
                v-if="$vuetify.display.mdAndUp"
                color="transparent"
                type="button"
                width="100px"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SingleTicketToBuy from "@/components/ticket/SingleTicketToBuy";
import { getAllTickets } from "@/services/ticket.service";

export default {
  name: "TicketShop",
  components: { SingleTicketToBuy },
  data() {
    return {
      tickets: [],
      loading: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.getTickets();
  },
  methods: {
    async getTickets() {
      this.loading = true;
      this.tickets = await getAllTickets();
      this.loading = false;
    },
    generateRepeatedText(text) {
      return (text + " // ").repeat(20);
    },
  },
};
</script>

<style scoped>
.sold-out {
  color: red;
  font-weight: bold;
}

.headline-container {
  width: 120%;
  overflow: hidden;
}

.scrolling-headline {
  white-space: nowrap;
  color: #ffffff24;
  font-family: "Neue Singular H", Sans-serif;
  font-size: 3rem;
  font-weight: 600;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  stroke: #ffffff;
  stroke-width: 1px;
}

.d-md-none {
  display: block;
  width: 100%;
  text-align: center;
  white-space: normal;
}
</style>
