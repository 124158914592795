<template>
  <div v-if="inProfile" class="feed-container">
    <FeedItem
      class="feed-item rounded-lg elevation-2"
      v-for="feedItem of feed"
      :key="feedItem.id"
      @updateItem="updateFeedItem"
      :content="feedItem"
    />
  </div>
  <div v-else>
    <FeedItem
      v-for="feedItem of feed"
      :key="feedItem.id"
      @updateItem="updateFeedItem"
      :content="feedItem"
    />
  </div>
</template>

<script>
import FeedItem from "@/components/feed/FeedItem";
import { getFeedOfUser } from "@/services/lobby.service";
import {Page} from "@/models/page";
export default {
  name: "FeedOfUser",
  components: { FeedItem },
  props: {
    userId: String,
    inProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: new Page(),
    }
  },
  computed: {
    feed() {
      return this.$store.state.feed.page.content;
    },
  },
  created() {
    this.getFeed();
  },
  methods: {
    updateFeedItem(item) {
      this.feed[this.feed.findIndex((x) => x.id === item.id)] = item;
    },
    async getFeed() {
      this.feed = await getFeedOfUser(this.userId, this.page.number+1);
    },
  },
};
</script>

<style scoped>
.feed-container {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem;
  scroll-snap-type: x mandatory;
}

.feed-container::-webkit-scrollbar {
  height: 8px;
}

.feed-container::-webkit-scrollbar-track {
  background: transparent;
}

.feed-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.feed-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.feed-item {
  min-width: 400px;
  flex: 0 0 auto;
  scroll-snap-align: start;
}
</style>
