import { createRouter, createWebHistory } from "vue-router";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import ConfirmationToken from "@/views/ConfirmationToken";
import RegisterVerification from "@/views/RegisterVerification";
import Logout from "@/views/Logout";
import EventSite from "@/views/Event/EventSite";
import Lobby from "@/views/Lobby/Lobby";
import Favourites from "@/views/Event/Favourites";
import UserDetails from "@/views/Lobby/UserDetails";
import LobbyNew from "@/views/Lobby/LobbyNew";
import WelcomeWrapper from "@/views/Welcome/WelcomeWrapper";
import ForYouPage from "@/views/Lobby/ForYouPage";
import LobbyCards from "@/views/Lobby/LobbyCards";
import store from "@/store";
import PushNotificationTest from "@/views/PushNotificationTest";
import EventArtists from "@/components/event/EventArtists";
import CreateEvent from "@/components/event/CreateEvent";
import CreateUser from "@/components/artist/CreateUser";
import AddTicket from "@/views/Ticket/AddTicket";
import AdminArtistList from "@/views/artist/AdminArtistList";
import AnonymousView from "@/views/anonym/AnonymousView";
import EventWrapper from "@/components/event/EventWrapper";
import SingleEventSite from "@/views/Event/SingleEventSite";
import CreateFeed from "@/views/Feed/CreateFeed";
import MyFeedView from "@/views/Feed/MyFeedView";
import ConferenceManagementWrapper from "@/views/conferenceManagement/ConferenceManagementWrapper";
import ManagementDashboard from "@/views/conferenceManagement/ManagementDashboard";
import TicketList from "@/components/ticket/TicketList";
import TicketShop from "@/components/ticket/TicketShop";
import UserCart from "@/components/cart/UserCart";
import AfterPayment from "@/components/order/payment/AfterPayment";
import CheckoutWrapper from "@/components/cart/checkout/CheckoutWrapper.vue";
import CartCheckout from "@/components/cart/checkout/CartCheckout.vue";
import BillPayment from "@/components/checkout/BillPayment.vue";
import PaymentIframe from "@/components/checkout/PaymentIframe.vue";

function guardMyRoute(to, from, next) {
  let isAuthenticated = store.state.auth.status.loggedIn;
  if (to.name !== "Login" && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
}

function guardManagementRoute(to, from, next) {
  let user = store.state.auth.user;
  if (
    user.roles.includes("ROLE_ADMIN") ||
    user.roles.includes("ROLE_PROMOTER") ||
    user.roles.includes("ROLE_CONF_MANAGER")
  ) {
    next();
  } else {
    next("/");
  }
}

const routes = [
  {
    path: "/cart",
    name: "Cart",
    component: UserCart,
  },
  {
    path: "/payment/done",
    name: "Payment Done",
    props: true,
    component: AfterPayment,
  },
  {
    path: "/shop",
    name: "Shop",
    component: TicketShop,
  },
  {
    path: "/checkout",
    name: "CheckoutWrapper",
    component: CheckoutWrapper,
    children: [
      {
        path: "",
        name: "Checkout",
        component: CartCheckout,
      },
      {
        path: "payment",
        name: "Bill",
        component: BillPayment,
      },
      {
        path: "payment/pay",
        name: "Payment",
        component: PaymentIframe,
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    beforeEnter: guardMyRoute,
    component: () => import("@/views/Lobby/LobbyNew.vue"),
  },
  {
    path: "/feed/create",
    name: "Create Feed",
    component: CreateFeed,
  },
  {
    path: "/feed",
    name: "Feed",
    component: MyFeedView,
  },
  {
    path: "/user/create/:role",
    name: "Create Artist",
    props: true,
    component: CreateUser,
  },
  {
    path: "/u",
    name: "Anonym",
    component: AnonymousView,
    children: [
      {
        path: "shop",
        name: "BDME SHOP",
        component: TicketShop,
      },
      {
        path: "reset/password/u/:userId/t/:token",
        name: "Password Reset",
        props: true,
        meta: { appBarBackButton: false, anonymous: true },
        component: () => import("@/views/anonym/ForgotPasswordView"),
      },
      {
        path: "reset/password/request",
        name: "Request Password Reset",
        meta: { appBarBackButton: false, anonymous: true },
        component: () => import("@/views/anonym/RequestPasswordForgotView"),
      },
      {
        path: "events",
        component: EventSite,
        meta: { hiddenHeaderAndFooter: true },
      },
      {
        path: "event/:eventId",
        component: EventWrapper,
        props: true,
        children: [
          {
            props: true,
            path: "",
            name: "Anonym Single Event Site",
            component: SingleEventSite,
            meta: {
              hiddenHeaderAndFooter: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/artist/list",
    name: "Artists",
    component: AdminArtistList,
  },
  {
    path: "/management",
    beforeEnter: guardManagementRoute,
    component: ConferenceManagementWrapper,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: ManagementDashboard,
      },
    ],
  },
  {
    path: "/event/:eventId",
    component: EventWrapper,
    props: true,
    children: [
      {
        props: true,
        path: "artists",
        name: "Event Artists",
        component: EventArtists,
      },
      {
        props: true,
        path: "",
        name: "Event",
        component: SingleEventSite,
      },
    ],
  },
  {
    path: "/create/event",
    name: "Create Event",
    component: CreateEvent,
  },
  {
    path: "/ticket/redeem",
    name: "Ticket",
    component: AddTicket,
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: TicketList,
  },
  {
    path: "/test-push",
    component: PushNotificationTest,
    name: "TestPush",
  },
  {
    path: "/auth/cognito",
    name: "Cognito",
  },
  {
    path: "/you",
    beforeEnter: guardMyRoute,
    name: "For You",
    component: ForYouPage,
  },
  {
    path: "/welcome",
    beforeEnter: guardMyRoute,
    name: "Welcome",
    component: WelcomeWrapper,
  },
  {
    path: "/start",
    beforeEnter: guardMyRoute,
    component: LobbyCards,
  },
  {
    path: "/user/:id",
    beforeEnter: guardMyRoute,
    component: UserDetails,
    props: true,
  },
  {
    path: "/events",
    beforeEnter: guardMyRoute,
    component: EventSite,
    name: "Events",
  },
  {
    path: "/lobby",
    beforeEnter: guardMyRoute,
    component: LobbyNew,
    name: "Lobby",
    props: true,
  },
  {
    path: "/lobby/:filter",
    beforeEnter: guardMyRoute,
    component: LobbyNew,
    props: true,
  },
  {
    path: "/lobbyOld",
    beforeEnter: guardMyRoute,
    component: Lobby,
  },
  {
    path: "/favourites",
    beforeEnter: guardMyRoute,
    component: Favourites,
    name: "My Timetable",
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/logout",
    beforeEnter: guardMyRoute,
    component: Logout,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    beforeEnter: guardMyRoute,
    name: "Profile",
    component: () => import("./views/Profile.vue"),
  },
  {
    path: "/confirm-account",
    beforeEnter: guardMyRoute,
    name: "Account Verifizierung",
    component: ConfirmationToken,
  },
  {
    path: "/successful-registration",
    beforeEnter: guardMyRoute,
    name: "Registrierung erfolgreich",
    component: RegisterVerification,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
