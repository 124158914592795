<template>
  <div
    @click:close="deleteProfession"
    variant="outlined"
    class="d-flex justify-space-between border border-opacity-100 rounded-lg border-black align-center w-100 pa-1 ma-1"
  >
    <v-text-field
      @change="updateProfession"
      v-model="professionC.role"
      style="border-bottom-right-radius: 5px; border-top-right-radius: 5px"
      placeholder="Role"
      color="transparent"
      class="w-100"
      hide-details
      flat
      variant="solo"
    ></v-text-field>
    <v-divider vertical></v-divider>
    <v-autocomplete
      @keypress.enter="addCompany(companyToSearch)"
      item-title="name"
      item-value="id"
      @change="updateProfession"
      :items="companies"
      :search-input.sync="companyToSearch"
      v-model="professionC.companyId"
      class="w-100 border-left"
      style="border-bottom-left-radius: 5px; border-top-left-radius: 5px"
      prefix="@"
      flat
      placeholder="Company"
      color="transparent"
      variant="solo"
      hide-details
    >
      <template v-slot:no-data>
        <v-list-item link @click="addCompany(companyToSearch)">
          <span>Create Company: <span v-html="companyToSearch"></span></span>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-btn
      @click="deleteProfession"
      size="small"
      variant="text"
      icon="mdi-close"
    ></v-btn>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
import { updateUser } from "@/services/user.service";
import Notification from "@/models/notification";

export default {
  name: "ProfessionItem",
  props: {
    profession: Object,
  },
  data() {
    return {
      companies: [],
      companyToSearch: "",
    };
  },
  computed: {
    professionC: {
      get() {
        return this.profession;
      },
      set(profession) {
        this.$emit("updateProfession", profession);
      },
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (
      !this.currentUser.professions ||
      this.currentUser.professions.length <= 0
    ) {
      this.addNewProfession();
    }
    this.getAllCompanies();
  },
  methods: {
    updateProfession() {
      ApiService.post("user/profession/update", this.professionC).then(
        (response) => {
          if (response.data) {
            updateUser(response.data);
          } else {
            this.$store.dispatch(
              "notificationsStore/add",
              new Notification(
                "error",
                "Cannot update Profession",
                "",
                "Please try again"
              )
            );
          }
        }
      );
    },
    deleteProfession() {
      let self = this;
      if (this.currentUser.professions.length <= 1) {
        this.$store.dispatch(
          "notificationsStore/add",
          new Notification(
            "warning",
            "Action not allowed",
            false,
            "You need at least 1 profession."
          )
        );
        return null;
      }
      ApiService.post("user/profession/remove", this.profession).then(
        (response) => {
          if (response.data) {
            updateUser(response.data);
          } else {
            self.$store.dispatch(
              "notificationsStore/add",
              new Notification(
                "error",
                "Cannot remove Profession",
                false,
                "Please try again"
              )
            );
          }
        }
      );
    },
    addCompany(companyName) {
      let self = this;
      ApiService.post("company/add", { id: null, name: companyName }).then(
        (response) => {
          self.companies.push(response.data);
          self.profession.companyId = response.data.id;
          self.$emit("updateProfession", self.profession);
        }
      );
    },
    getAllCompanies() {
      ApiService.get("company/find/all").then((response) => {
        this.companies = response.data;
      });
    },
  },
};
</script>
