<template>
  <div class="ticket-container">
    <div
      class="ticket custom-border-radius bg-white"
      :class="{ soldOut: ticket.soldOut }"
    >
      <!-- Ticket Inhalt -->
      <div class="ticket-content">
        <!-- Linke Seite -->
        <div class="ticket-left">
          <h2 class="ticket-title">{{ ticket.name }}</h2>
          <div class="ticked-info">
            <div
              class="additional-info px-5"
              v-html="ticket.additionalInformation"
            ></div>
            <!-- <v-dialog v-model="showMobileDescription">
              <template #activator="{ on }">
                <v-btn
                  class="hidden-sm-and-up pa-0 text-lowercase text-decoration-underline"
                  v-on="on"
                  variant="plain"
                  >open description</v-btn
                >
              </template>
              <v-card>
                <v-card-text class="pa-3" v-html="ticket.additionalInformation">
                </v-card-text>
              </v-card>
            </v-dialog> -->
          </div>
          <div class="mt-10 mb-md-5">
            <v-chip v-if="ticket.soldOut" color="error">Sold out!</v-chip>
            <div
              class="d-flex justify-center justify-md-start align-center"
              v-else-if="isAlreadyInCart != -1"
            >
              <v-btn small :loading="loading" color="primary" @click="removeOne"
                ><v-icon small>mdi-minus</v-icon></v-btn
              >
              <span
                class="px-5"
                small
                color="primary"
                v-text="isAlreadyInCart"
              ></span>
              <v-btn small :loading="loading" color="primary" @click="addToCart"
                ><v-icon small>mdi-plus</v-icon></v-btn
              >
            </div>

            <v-btn
              :loading="loading"
              v-else
              :block="$vuetify.display.smAndDown"
              flat
              color="primary"
              @click="addToCart"
              >Add to Cart</v-btn
            >
          </div>
        </div>

        <!-- Gestrichelte Trennlinie -->
        <div class="ticket-divider pl-3">
          <!-- Einkerbungen -->
          <div class="notch notch-top mx-n6 mt-md-n5"></div>
          <div class="notch notch-bottom mx-n6 mb-md-n5"></div>
        </div>

        <!-- Rechte Seite -->
        <div
          class="d-flex ticket-right flex-column justify-center align-start py-3 py-md-0 mx-md-6 align-md-center"
        >
          <div>
            <p class="price-text">{{ ticket.price }} {{ ticket.currency }}</p>
            <p class="vat-text">incl. 19% VAT</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleTicketToBuy",
  props: {
    ticket: Object,
  },
  data() {
    return {
      showMobileDescription: false,
      loading: false,
    };
  },
  computed: {
    isAlreadyInCart() {
      let self = this;
      if (
        this.ticket &&
        this.cart.findIndex((x) => x.product.uid === self.ticket.uid) != -1
      )
        return this.cart[
          this.cart.findIndex((x) => x.product.uid === self.ticket.uid)
        ]?.amount;
      else return -1;
    },
    cart() {
      return this.$store.state.cart.cart;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    addToCart() {
      this.loading = true;
      console.log("Amount", this.isAlreadyInCart);
      if (this.currentUser) this.$store.dispatch("cart/addToCart", this.ticket);
      else {
        if (this.isAlreadyInCart > 0)
          this.$store.dispatch("cart/addOneAnonym", {
            amount: this.isAlreadyInCart,
            product: this.ticket,
          });
        else
          this.$store.dispatch("cart/addToCartAnonym", {
            amount: 0,
            product: this.ticket,
          });
      }
      console.log("Loading end", this.loading);
      this.loading = false;
    },
    async removeOne() {
      this.loading = true;
      if (this.isAlreadyInCart > 1) {
        if (this.currentUser)
          this.$store.dispatch("cart/removeOne", { product: this.ticket });
        else
          this.$store.dispatch("cart/removeOneAnonym", {
            amount: this.isAlreadyInCart,
            product: this.ticket,
          });
      } else if (this.isAlreadyInCart <= 1 && this.isAlreadyInCart != -1) {
        await this.removeFromCart();
      }
      this.loading = false;
    },
    removeFromCart() {
      this.loading = true;
      if (this.currentUser)
        this.$store.dispatch("cart/removeFromCart", { product: this.ticket });
      else
        this.$store.dispatch("cart/removeFromCartAnonym", {
          amount: this.isAlreadyInCart,
          product: this.ticket,
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.ticket-container {
  padding: 15px;
}

.ticket {
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ticket-content {
  display: flex;
  padding: 20px;

  position: relative;
  min-height: 150px;
}

.ticket-left {
  flex: 7;
  position: relative;
}

.ticket-right {
  width: 15%;
}
.ticket-title {
  font-size: 30px;
  font-weight: bold;
}

.ticket-divider {
  position: relative;

  border-left: 2px dashed #ccc;
  margin: 0 20px;

  height: auto;
}

.notch {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #4c2358;
  border-radius: 50%;
}

.notch-top {
  top: -10px;
}

.notch-bottom {
  bottom: -10px;
  top: auto;
}

/* Mobile Ansicht */
@media (max-width: 959px) {
  .ticket-content {
    flex-direction: column;
  }

  .ticket-divider {
    width: 100%;
    height: 0;
    border-left: none;
    border-top: 2px dashed #ccc;
    margin: 20px 0;
  }
  .ticket-right {
    width: 100%;
  }

  .notch {
    width: 20px;
    height: 20px;

    left: auto;
  }

  .notch-top {
    right: -8px;
    background: linear-gradient(270deg, #733686 100%, black 0%);
  }

  .notch-bottom {
    background: black;
    right: auto;
    left: -8px;
    top: -9px;
  }
  .ticket-title {
    font-size: 24px;
    font-weight: bold;
  }
}

.ticket-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.price-text {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0px;
}

.vat-text {
  font-size: 12px;
  color: #666;
}

.additional-info {
  margin-top: 10px;
}

.v-btn {
  text-transform: uppercase;
}

.soldOut {
  opacity: 0.7;
}
</style>
