<template>
  <v-dialog max-width="500px" v-model="showDialog">
    <template #activator="{props}">
      <div v-bind="props" style="position: relative;height: 200px;max-height:200px;min-width:400px;max-width: 400px;width:400px;overflow: hidden" class="my-2 custom-border-radius">
        <v-img  cover class="custom-border-radius" style="position: absolute;top:0;left:0;z-index:0;opacity: 1;width:100%;height:100%;max-height: 100%" :src="image"></v-img>
        <div class="custom-border-radius" style="position: absolute;top:0;left:0;background-color: black;z-index:1;opacity: 0.5;width:100%;height:100%;max-height: 100%;"></div>
        <span style="position: absolute;bottom:0;left:0; z-index:2; font-size: 1.4rem;" class="text-secondary pa-3 text-uppercase font-weight-bold" v-text="venue.name"></span>
      </div>
    </template>
    <v-card >
      <v-card-text class="pa-5">
        <v-text-field class="custom-border-radius" solo v-model="venueC.name"/>
        <ImagePicker :image="image" @updateImage="updateImage"/>
        <v-text-field placeholder="website" class="my-5 custom-border-radius" solo v-model="venueC.website"/>
      </v-card-text>
      <v-card-actions >
        <div style="width:100%">
        <v-btn :loading="loading" class="custom-border-radius" block color="primary" @click="updateVenue">Update</v-btn>
      <v-btn block class="mt-2 custom-border-radius" color="red" outlined @click="deleteVenue"> Delete</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import Venue from "@/models/venue";
import {addMetaToBase64} from "@/services/image.service";
import ImagePicker from "@/components/ImagePicker";
import {deleteVenue, updateVenue} from "@/services/venue.service";

export default {
  name: "VenueAdminItem",
  components: {ImagePicker},
  props:{
    venue:Venue
  },
  data(){
    return{
      showDialog:false,
      loading:false
    }
  },
  methods:{
    async updateVenue() {
      this.loading=true;
      this.venueC = await updateVenue(this.venue);
      this.showDialog=false;
      this.loading=false;
    },
    updateImage(image){
      this.venueC.image=image;
    },
    deleteVenue: async function () {
      if(confirm("Do you really want to delete "+this.venueC.name+"?")){
        let data = await deleteVenue(this.venueC.id);
        if(data) this.$emit("delete",this.venueC.id);
      }

    }
  },
  computed:{
    venueC:{
      get(){
        return this.venue
      },set(value){
        this.$emit("updateVenueF",value);
      }
    },
    image(){
      return addMetaToBase64(this.venueC.image,require('@/assets/Placeholder-Profile-819x1024.webp'));
    }
  }
}
</script>

<style scoped>

</style>