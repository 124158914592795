<template>
<v-form class="pa-5">

  <label class="custom-label">
    Eventname
  <v-text-field class="input-border" solo v-model="event.title"/>
  </label>
  <label class="custom-label">
    Description
  <v-textarea class="input-border" solo v-model="event.description"/>
  </label>
  <label class="custom-label">
    Genre
    <div class="d-flex justify-start flex-wrap">
      <GenrePicker :genre-to-edit="eventToEdit?.genres" @update="updateGenre"/>
    </div>
  </label>
  <label class="custom-label">
    Ticketlink
  <v-text-field class="input-border" solo v-model="event.ticketLink"/>
  </label>
  <label class="custom-label">
    Date
    <v-dialog
        v-model="dateDialog"
        :close-on-content-click="false"
        transition="scale-transition"

        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            class="input-border"
            solo
            placeholder="click to set..."
            v-model="date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" @input="dateDialog = false"></v-date-picker>

    </v-dialog>
  </label>
    <label class="custom-label">
    Start
    <v-dialog
        ref="timeDialog"
        v-model="timeDialog"
        :return-value.sync="time"
        persistent
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            placeholder="click to set..."
            v-model="time"
            prepend-icon="mdi-clock-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            solo
        ></v-text-field>
      </template>
      <v-time-picker
          v-if="timeDialog"
          v-model="time"
          full-width
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="timeDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="$refs.timeDialog.save(time)"
        >
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </label>
  <label class="custom-label">
    Enddate
    <v-dialog
        v-model="dateDialogEnd"
        :close-on-content-click="false"
        transition="scale-transition"

        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            class="input-border"
            solo
            placeholder="click to set..."
            v-model="dateEnd"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="dateEnd" @input="dateDialogEnd = false"></v-date-picker>

    </v-dialog>
  </label>
  <label class="custom-label">
    Endtime
    <v-dialog
        ref="endDialog"
        v-model="endDialog"
        :return-value.sync="end"
        persistent
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            placeholder="click to set..."
            v-model="end"
            prepend-icon="mdi-clock-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            solo
        ></v-text-field>
      </template>
      <v-time-picker
          v-if="endDialog"
          v-model="end"
          full-width
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="endDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="$refs.endDialog.save(end)"
        >
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </label>
  <label class="custom-label">
    Venue
    <v-combobox hide-no-data  :items="allVenues" class="input-border" solo v-model="event.venue"/>
  </label>
<label class="custom-label">
  Artists
  <v-autocomplete
      class="input-border"
      v-model="event.artists"
      :items="artists"
      item-value="id"
      item-text="name"
      item-title="name"
      chips
      small-chips
      label="Select Artists"
      multiple
      solo
  ></v-autocomplete>
</label>
  <v-switch hint="Is the Event a Festival or Conference?" inset v-model="event.conference">
    <template v-slot:label>
      <div>
      <div v-if="event.conference">Conference</div>
      <div v-else>Festival</div>
      </div>
    </template>
  </v-switch>
  <label v-if="event.conference" class="custom-label">
    Conference Categories
    <v-select :menu-props="{ top: true, offsetY: true }"  placeholder="Select Categories..." solo multiple small-chips return-object v-model="event.conferenceTopics" :items="conferenceTopics"/>
  </label>
  <label v-if="event.conference" class="custom-label">
    Conference Type
    <v-select placeholder="Select Topic..." solo small-chips return-object :items="conferenceTypes" v-model="event.conferenceType"></v-select>
  </label>
  <div style="max-width: 70%" class="d-flex mx-auto justify-space-between">
    <v-btn text :disabled="loading" @click="cancel">Cancel</v-btn><v-btn v-if="!event.id" @click="save" :loading="loading" outlined>Save</v-btn><v-btn v-else :loading="loading" @click="update" outlined>Update</v-btn>
  </div>
</v-form>
</template>

<script>
import Event from "@/models/event"
import {getAllArtists} from "@/services/artist.service";
import GenrePicker from "@/components/artist/GenrePicker";
import {createEvent, updateEvent} from "@/services/event.service";
import {getAllVenueNames} from "@/services/venue.service";
export default {
  name: "CreateEvent",
  components: {GenrePicker},
  props:{
    eventToEdit:Event
  },
  data(){
    return{
      conferenceTopics:[
        "Strategies",
        "Opportunities",
        "Business",
        "Responsibilities",
        "Networking",
        "Artists",
        "Newcomer",
        "Creation",
        "Management",
        "Labels & Publishing",
        "Events",
        "Marketing",
        "Streaming",
        "Legal",
        "Media",
        "Technology",
        "AI",
        "Data",
        "Startups",
        "Sustainability",
        "Social Change",
        "Inclusivity",
        "Diversity",
        "Culture",
        "(Mental) Health",
        "Global Scenes",
        "Local Scenes",
        "Visual",
        "Gear",
        "DJ/Producer",
        "Journalism",
        "Mix & Master",
        "Curation",
        "Remix",
        "Music Professional",
        "Artist Course",
        "Producer Course",
        "Fashion"
      ],
      conferenceTypes:[
        "Masterclass",
        "Keynote",
        "Q&A",
        "Networking",
        "Contests/Pitches",
        "Live Performance",
        "Interview",
        "Paneltalk",
        "Workshop",
        "Exhibition"
      ],
      allVenues:[],
      loading:false,
      artists:[],
      timeDialog:false,
      dateDialog:false,
      endDialog:false,
      dateDialogEnd:false,
      end:null,
      date:null,
      time:null,
      event:new Event(),
      dateEnd:null,
     }

  },
  watch:{
    date(){
      if(this.date && this.time) this.event.dateTime=this.combineDateTime(this.date,this.time);
      //if(this.date && this.end) this.event.end = this.combineDateTime(this.date,this.end);
    },
    time(){
      if(this.date && this.time) this.event.dateTime=this.combineDateTime(this.date,this.time);
    },
    dateEnd(){
      if(this.dateEnd && this.end) this.event.end = this.combineDateTime(this.dateEnd,this.end);
    },
    end(){
      if(this.date && this.end) this.event.end = this.combineDateTime(this.dateEnd,this.end);
    }
  },
  created() {
    this.getAllVenues();
    if(this.eventToEdit){
      let moment = require("moment");
      moment.locale('de');
      this.date = moment(this.eventToEdit.dateTime).format('YYYY[-]MM[-]DD');
      this.time = moment(this.eventToEdit.dateTime).format("HH:mm");
      this.dateEnd = moment(this.eventToEdit.end).format('YYYY[-]MM[-]DD');
      this.end = moment(this.eventToEdit.end).format("HH:mm");
      if(this.dateEnd === "Invalid date") this.dateEnd=null;
      if(this.end=== "Invalid date") this.end=null;
      this.event=JSON.parse(JSON.stringify(this.eventToEdit));
    }
    this.getArtists();
  },
  methods:{
    async getAllVenues() {
      this.allVenues = await getAllVenueNames();
    },
    cancel(){
      this.$emit("close");
    },
    async save() {
      this.loading=true;
      let response = await createEvent(this.event);
      this.loading=false;
      if(response.status==200) this.$emit("success",response.data);
      this.$emit("close");

    },
    async update(){
      this.loading=true;
      let response = await updateEvent(this.event);
      this.loading=false;
      if(response.status==200) this.$emit("success",response.data);
    },
    updateGenre(genre){
      console.log("genres",genre)
      this.event.genres=genre;
    },
    async getArtists() {
      this.artists = await getAllArtists();
    },
    combineDateTime(dateString, timeString) {
  const [year, month, day] = dateString.split('-').map(Number);
  const [hours, minutes] = timeString.split(':').map(Number);

  // Create a new Date object with the date part
  const combinedDateTime = new Date(year, month - 1, day);

  // Set the time part of the date object
  combinedDateTime.setHours(hours);
  combinedDateTime.setMinutes(minutes);

  return combinedDateTime;
}

}
}
</script>

<style scoped>

</style>