<template>
  <v-list>

    <v-list-item title="Auf Website sichtbar" prepend-icon="mdi-eye" style="max-width: 300px">
    </v-list-item>
    <v-list-item title="Nicht auf Website sichtbar" prepend-icon="mdi-eye-closed" style="max-width: 300px">
    </v-list-item>
    <v-text-field @keyup="debounceInit" variant="solo" class="input-border mx-5" placeholder="Search for artist" prepend-inner-icon="mdi-magnify" v-model="searchQuery"></v-text-field>
    <v-select @update:modelValue="debounceInit" :items="years" v-model="filterYears" multiple label="Filter by year" class="mx-5"></v-select>
    <div class="mx-6" style="font-size:0.9rem"><span>{{page.totalElements}} Artists found</span></div>
    <div style="width:100%" class="d-flex justify-end px-5">
      <v-btn :loading="loading" size="x-small" @click="getAllArtistsByPage">Refresh <v-icon x-small>mdi-refresh</v-icon></v-btn>
    </div>
    <v-dialog v-model="showCreateArtistDialog">
      <template #activator="{props}">
        <v-list-item title="Add new Artist" v-bind="props" prepend-icon="mdi-plus" link class="offwhite mx-5 my-2">


        </v-list-item>
      </template>
      <v-sheet>
        <CreateUser @cancel="() => this.showCreateArtistDialog = false" @save="() => this.showCreateArtistDialog = false" role="ROLE_ARTIST"/>
      </v-sheet>
    </v-dialog>
    <ArtistListItem @deleteUser="deleteUser" v-for="artist of artists" :key="artist.id" :artist="artist" @updateArtist="updateArtist"/>
    <v-pagination v-if="page.totalPages > 1" v-model="page.number" :length="page.totalPages" @update:modelValue="getAllArtistsByPage" class="mx-5">
    </v-pagination>
    <div class="d-flex justify-centerf">
      <!--<v-btn @click="loadMore" elevation="0" small class="mx-auto">load more</v-btn>
-->
    </div>

  </v-list>
</template>

<script>
import { debounce } from "lodash";
import ArtistListItem from "@/components/artist/ArtistListItem";
import { getMyArtistsSimpleByPage} from "@/services/artist.service";
import CreateUser from "@/components/artist/CreateUser";
import {Page} from "@/models/page";
import PaginationWrapper from "@/components/PaginationWrapper.vue";
export default {
  name: "AdminArtistList",
  components: {PaginationWrapper, CreateUser, ArtistListItem},
  data(){
    return{
      loading:false,
      searchQuery:null,
      years:[2024,2025],
      filterYears:[2025],
      artists:[
      ],

      page:new Page(),
      showCreateArtistDialog:false
    }
  },
  computed:{
    filter(){
      return {query:this.searchQuery,years:this.filterYears}
    },
   artists(){
        return this.page.content
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    hasRoleAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
  },
  created() {
    this.debouncedInitList = debounce(this.initList, 200); // Adjust the debounce delay as needed
    this.initList();
  },
  methods:{
    deleteUser(id){
      if(this.artists){
        this.artists.splice(this.artists.findIndex(x => x.id ===id),1);
      }
    },
    updateArtist(artist){
      if(this.artists){
        let index = this.artists.findIndex(x => x.id === artist.id);
        if(index !== -1){
          this.artists[index] = artist;
        }
      }
    },
    debounceInit(){
      console.log("debounceInit");
      this.debouncedInitList();
    },
    async initList() {
      console.log("initList");
      this.loading = true;
      let response = await getMyArtistsSimpleByPage({
        query: this.searchQuery,
        years: this.filterYears
      }, 0);
      //TODO not = on this.artists so the list gets expandable
      this.page = response;
      this.loading = false;
    },
    loadMore(){
      this.getAllArtistsByPage();
    },
    async getAllArtistsByPage() {
      this.loading=true;
      let response = await getMyArtistsSimpleByPage({query:this.searchQuery,years:this.filterYears},this.page.number+1);
      //TODO not = on this.artists so the list gets expandable
      this.page=response;
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>