<template>
  <v-dialog v-model="show" style="z-index: 99999">
    <template #activator="{ props }">
      <v-btn text v-bind="props">My Orders</v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <h2>My Orders</h2>
        <v-btn
          variant="outlined"
          class="ms-4"
          prepend-icon="mdi-refresh"
          :loading="loading"
          @click="updateOrders"
          >Update</v-btn
        >
        <v-spacer />
        <v-btn
          class="position-absolute top-0 right-0 ma-2"
          icon
          variant="text"
          @click="show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <h3 class="my-5" v-if="orders.length <= 0 && !loading">
          You have no Orders
        </h3>
        <OrderList v-else :orders="orders" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderList from "@/components/order/OrderList";
export default {
  name: "MyOrders",
  components: { OrderList },
  data() {
    return {
      show: false,
      loading: false,
      orders: [],
    };
  },
  computes: {
    orders() {
      return this.$store.state.cart.orders;
    },
  },
  created() {
    this.getMyOrders();
  },
  methods: {
    async updateOrders() {
      this.loading = true;
      this.orders = await this.$store.dispatch("cart/updateOrders");
      this.loading = false;
    },
    async getMyOrders() {
      this.loading = true;
      this.orders = await this.$store.dispatch("cart/getOrders");
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
