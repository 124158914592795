<template>
<div class="d-flex flex-wrap justify-start" style="gap:20px">
  <VenueAdminItem v-for="(venue,$index) of venues" @updateVenue="updateVenue" @delete="deleteVenue" :key="$index" :venue="venue"/>
</div>
</template>

<script>
import VenueAdminItem from "@/components/management/venue/VenueAdminItem";
import {getAllVenues} from "@/services/venue.service";
export default {
  name: "VenueAdminList",
  components: {VenueAdminItem},
  data(){
    return{
      venues:[],

    }
  },
  created() {
    this.getVenues();
  },
  methods:{
    deleteVenue(id){
      if(id && this.venues) this.venues.splice(this.venues.findIndex(x=> x.id ===id),1);
    },
    updateVenue(venue){

      let index = this.venues.findIndex(x => x.name === venue.name);
      if(index != 1) this.venues[index] = venue;
    },
    async getVenues() {
      this.venues = await getAllVenues()
    }
  }
}
</script>

<style scoped>

</style>