<template>
  <div>
    <!-- Cart Button -->

    <v-btn @click="toggleCart" :icon="!fab" class="ma-5" :fab="fab">
      <v-badge :content="totalItems" color="grey-darken-1" text-color="white">
        <v-icon color="white">mdi-cart</v-icon>
      </v-badge>
      <!-- <span v-if="totalItems > 0">{{ totalItems }}</span> -->
    </v-btn>
  </div>
</template>

<script>
import UserCart from "@/components/cart/UserCart";
import MyOrders from "@/components/order/MyOrders.vue";
// import RegisterFromCart from "@/components/auth/RegisterFromCart";
import SelectPaymentMethod from "@/components/order/payment/SelectPaymentMethod";
import { getBill } from "@/services/ticket.service";
import Notification from "@/models/notification";
// import OrderBill from "@/components/cart/OrderBill";
// import PayOrder from "@/components/order/payment/PayOrder";
export default {
  name: "CartButtom",
  components: {
    // PayOrder,
    // OrderBill,
    SelectPaymentMethod,
    // RegisterFromCart,
    MyOrders,
    UserCart,
  },
  data() {
    return {
      show: false,
      bill: null,
      showBill: false,
      paymentMethod: null,
      orderSummary: null,
      showPayment: false,
    };
  },
  props: {
    fab: Boolean,
  },
  async created() {
    if (this.currentUser) await this.$store.dispatch("cart/getCart");
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    totalItems() {
      return this.cart.length;
    },
    total() {
      let Euro = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      let response = 0;
      for (let item of this.cart) {
        response += item.amount * item.product.price;
      }
      return Euro.format(response);
    },
    cart() {
      return this.$store.state.cart.cart;
    },
  },
  methods: {
    toggleCart() {
      this.$store.dispatch("cart/toggleCart");
    },
    updatePaymentMethod(value) {
      console.log(value);
      this.paymentMethod = value;
    },

    async buy() {
      let order = await this.$store.dispatch("cart/buy", {
        paymentMethod: this.paymentMethod,
        products: this.cart,
      });
      console.log("Order", order);
      if (order && order.paymentLink) {
        location.href = order.paymentLink;
        //this.orderSummary=order;
        //this.showPayment=true;
      } else {
        location.href = "/payment/done?order_state=completed";
      }
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  display: flex;
  flex-direction: column;
}
</style>
