export class Page {
  constructor() {
    this.content = [];
    this.pageable = {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true
      },
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
      unpaged: false
    };
    this.totalElements = -1;
    this.totalPages = 0;
    this.last = false;
    this.size = 0;
    this.number = -1;
    this.sort = {
      sorted: false,
      unsorted: true,
      empty: true
    };
    this.numberOfElements = 0;
    this.first = true;
    this.empty = false;
  }
}