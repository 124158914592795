import {createFeed, getMyFeed} from "@/services/lobby.service";
import {Page} from "@/models/page";
export const feed = {
    namespaced: true,
    state: {
        page:new Page(),
    },
    actions: {
        async createItem({commit},feed){
            let response = await createFeed(feed);
            if(response){
                commit("addItem",response);
            }
        },
        async loadFeedItems({commit}) {
            commit("setFeedItems");
        },
    },

    mutations: {
        addItem(state,item){
            state.page?.content?.push(item);
        },
        async setFeedItems(state) {
            let content= [...state.page.content];
            let page = await getMyFeed(state.page.number + 1);
            state.page = page;
            state.page.content = content.concat(page.content);
        }

    }
};