import ApiService from "@/services/api.service";

export async function getMyOrders(){
    let response = await ApiService.get("order/my");
    return response.data;
}
export async function updateMyOrdersFromSeeTickets(){
    let response = await ApiService.get("order/my/update");
    return response.data;
}

export async function getOrderById(id,email){
    return await ApiService.betterPost("order/get",{orderId:id,email:email});
}