<template>
  <div
    @click="navigateToUser(user.id)"
    style="
      cursor: pointer;
      position: relative;
      width: 100%;
      border-radius: 25px;
      height: 225px;
    "
    :style="{ height: size * 200 + 'px' }"
    class="wall-item"
    :class="{
      'box--double': user.bigDisplay && index % 2 == 0,
      currentUser: user.id === currentUser.id && !profileView,
    }"
  >
    <v-btn
      @click.prevent="$router.push('/profile')"
      small
      style="position: absolute; top: 0; left: 0; z-index: 3"
      class="ma-2"
      color="white"
      v-if="user.id === currentUser.id && !profileView"
      >Me <v-icon small>mdi-pencil</v-icon></v-btn
    >

    <v-img v-if="profileView" height="100%"
           width="100%"
           cover
           :src="
        user.profilePic?.file
          ? 'data:image/webp;base64,' + user.profilePic?.file
          : require('../../assets/Placeholder-Profile-819x1024.webp')
      "
           class="background-image">

    </v-img>
    <v-img v-else
      height="100%"
      width="100%"
      cover
      :src="
        user.image
          ? 'data:image/webp;base64,' + user.image
          : require('../../assets/Placeholder-Profile-819x1024.webp')
      "
      class="background-image"
    ></v-img>
    <div class="background-overlay"></div>
    <div
      style="max-width: 100%"
      class="text--secondary line-height background-name d-flex flex-column align-center justify-center flex-wrap"
    >
      <span
        :style="{ 'font-size': fontSize }"
        class="text-uppercase font-weight-bold text-center"
        ><span v-html="user.name"> </span
      ></span>
      <!--<v-expand-transition class="text-left">-->
      <v-tooltip v-if="user.companies && user.companies.length > 0" bottom>
        <template v-slot:activator="{ attrs, on }">
          <span
            v-on="on"
            v-bind="attrs"
            style="max-width: 100%"
            class="text-truncate"
            v-show="size > 0.5"
          >
            <div
              v-if="user.companies"
              class="mx-auto"
              style="margin: 5px 0; width: 80%; border-bottom: 1px solid white"
            ></div>
            <span v-for="(company, $index) of user.companies" :key="company"
              ><span v-text="company"></span
              ><span v-if="$index < user.companies.length - 1" class="mr-1"
                >,</span
              ></span
            >
          </span>
        </template>
        <span v-for="(company, $index) of user.companies" :key="company"
          ><span v-text="company"></span
          ><span v-if="$index < user.companies.length - 1" class="mr-1"
            >,</span
          ></span
        >
      </v-tooltip>
      <!--</v-expand-transition>-->
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";

export default {
  name: "UserSheetWall",
  props: {
    profileView: Boolean,
    hover: {},
    user: {},
    size: {
      type: String,
      default: "0.6",
    },
    index: Number,
  },
  data() {
    return {
      tags: [],
      professions: [],
    };
  },
  created() {
    this.getUserTags();
    this.getProfessions(this.user.id);
  },
  methods: {
    getUserTags() {
      let self = this;
      ApiService.post("tags/get/custom", { ids: self.user.tags }).then(
        (response) => {
          self.tags = response.data;
          self.$store.dispatch("loading/success");
        }
      );
    },
    getProfessions(userId) {
      let self = this;
      ApiService.get("company/find/from/" + userId).then((response) => {
        self.professions = response.data;
        //console.log("Profession",response.data)
      });
    },
    navigateToUser(userId) {
      location.href = "/user/" + userId;
    },
  },
  computed: {
    userWithArtistName() {
      let list = ["ROLE_ARTIST", "ROLE_CONF_MANAGER", "ROLE_PROMOTER"];
      console.log("UserRoles", this.user.roles);
      for (let role of list) {
        let hasRole = this.user.roles.findIndex((x) => x.name === role) !== -1;
        if (hasRole) return true;
      }
      return false;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    fontSize() {
      return 25 * this.size + "px";
    },
  },
};
</script>
<style scoped>
.wrapper > div {
  width: 100%;
  background-color: #e0e0e0;
  border: solid 1px white;
}

.box--double {
  grid-column: auto / span 2;
  grid-row: auto / span 2;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 25px !important;
}
.currentUser {
  border: 5px solid var(--v-offwhite-darken2) !important;
  border-style: inset;
  border-radius: 30px !important;
}
.background-overlay {
  background-color: black;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  transition: opacity 0.5s ease-in-out;
}
.line-height {
  line-height: 10px;
}

.background-name {
  width: 100%;
  transition: background-color 0.5s ease-in-out;
  position: absolute;
  padding: 1em;
  left: 0;
  bottom: 0;
  text: center;
}

.wall-item:hover {
  outline: 3px solid black;
}
.background-name span {
  color: white !important;
}
.wall-item {
  overflow: hidden;
}
.wall-item .background-overlay {
  opacity: 0.3;
  border-radius: 25px !important;
}
.wall-item .background-name {
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  opacity: 0.9;
}
.tag:hover {
  text-decoration: underline;
}
@media (max-width: 959px) {
  .line-height {
    line-height: 0px;
  }

  .wall-item {
    height: 150px !important; /* Größere Höhe für mobile Ansicht */
    width: auto !important;
  }
}
</style>
