<script>
export default {
  name: "PaymentIframe",
  props:{
  },
  computed:{
    paymentLink(){
      return this.$route.query.l;
    },
  },
  created() {
    if(this.paymentLink) this.openPaymentInNewTab();
  },
  methods:{
    openPaymentInNewTab() {
      window.open(this.paymentLink, '_blank','noopener,noreferrer,width=800,height=600');
    },
    cancelPayment(){
      this.$emit("cancel",false);
      this.$router.push("/payment/done?canceled");
    }
  }
}
</script>

<template>
  <v-sheet class="d-flex flex-wrap justify-center align-center" height="80vh">
    <div class="text-center">
      <h2>You are being redirected to the payment page...</h2>
      <p>If you are not redirected, please click the button below.</p>
      <v-btn tile class="rounded" variant="text" @click="openPaymentInNewTab">Go to Payment</v-btn>
    </div>

    <!--<iframe height="100%" width="100%" :src="paymentLink"></iframe>-->
  </v-sheet>
</template>

<style scoped>

</style>