import { createVuetify } from "vuetify";
import "vuetify/dist/vuetify.min.css";

import de from "vuetify/lib/locale/de";
import en from "vuetify/lib/locale/en";
import "vuetify/styles";
// Vuetify
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
const vuetify = createVuetify({
  components,
  directives,
  lang: {
    locales: { de, en },
    current: "en",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        colors: {
          primary: "#1A1A1A",
          secondary: "#FFFFFF",
          accent: "#DB54EFd",
          error: "#FF5252",
          info: "#104380",
          success: "#4CAF50",
          warning: "#FFC107",
          text: "#F2F2F2",
          offwhite: "#f5f5f5",
          white: "#FFFFFF",
          grey: "#ADADAD",
          whitevariant: "#F4F4F4",
          lightblack: "#1A1A1A",
          background: "#ffffff",
        },
      },
      dark: {
        colors: {
          primary: "#FFFFFF",
          secondary: "#1e1e1e",
          whitevariant: "#292929",
          background: "#e5e5e5",
        },
      },
    },
  },
});

export default vuetify;
