<template>
  <v-list-item class="my-3">
    <template #prepend>
      <v-avatar size="40">
        <v-img
          :src="
            comment.autor.profilePic
              ? 'data:image/png;base64,' + comment.autor.profilePic
              : require('@/assets/Placeholder-Profile-819x1024.webp')
          "
        >
        </v-img>
      </v-avatar>
    </template>
    <template #title>
      <p style="font-size: 0.7rem">
        <span v-text="comment.autor.name"></span
        ><span class="font-weight-bold ml-2">{{ date }}</span>
      </p>
    </template>

    <p color="black" v-text="comment.text"></p>

    <template #append>
      <v-btn
        size="x-small"
        v-if="comment.autor.id === currentUser.id"
        variant="icon"
        @click="deleteComment"
        ><v-icon>mdi-delete</v-icon></v-btn
      >
    </template>
  </v-list-item>
</template>

<script>
export default {
  name: "CommentListItem",
  props: {
    comment: Object,
  },
  methods: {
    deleteComment() {
      if (confirm("Are you sure? The comment will be permanently deleted.")) {
        this.$emit("deleteComment", this.comment.id);
      } else {
        return null;
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    date() {
      let moment = require("moment");
      return moment(this.comment.creationDate).fromNow();
    },
  },
};
</script>

<style scoped></style>
