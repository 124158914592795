import ApiService from "@/services/api.service";
import store from "@/store";
import Notification from "@/models/notification";

export async function getUserListForAdmin() {
  let response = await ApiService.get("user/get/all/adminList");
  return response.data;
}

export async function updateUserRoles(userId, roleNames) {
  let response = await ApiService.post("user/roles/update", {
    userId: userId,
    roles: roleNames,
  });
  return response.data;
}

export async function sendAccountWelcomeEmail(userId, text) {
  let response = await ApiService.post("user/welcome/send", {
    userId: userId,
    text: text,
  });
  return response.data;
}

export function updateUser(newUser) {
  ApiService.post("user/update", newUser).then((response) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let rolesTmp = response?.data?.roles?.map((obj) => obj.name);
    console.log("ROles  TMp", rolesTmp);
    let merged = { ...user, ...response.data };
    merged.roles = rolesTmp;
    store.dispatch("auth/updateLocalUser", merged);
    store.dispatch("loading/success");
  });
}

export function searchForUser(search) {
  return ApiService.get("user/search/email/" + search);
}

export async function updateGenres(genreIds) {
  console.log("Genres", genreIds);
  let response = await ApiService.post("user/genres/update", { ids: genreIds });
  let user = JSON.parse(localStorage.getItem("user"));
  user.genre = response.data;
  store.dispatch("auth/updateLocalUser", user);

  return response.data;
}

export async function updateTags(tags) {
  const response = await ApiService.post("user/tags/save", tags);
  if (response.status === 200) {
    let user = JSON.parse(localStorage.getItem("user"));
    let merged = (user.tags = response.data);
    store.dispatch("auth/updateLocalUser", merged);
    store.dispatch("loading/success");
  }
}

export async function inviteUser(userId) {
  const response = await ApiService.get("user/invite/" + userId);
  if (response.status === 200 && response.data) {
    await store.dispatch(
      "notificationsStore/add",
      new Notification("success", "Invitation got sent successfully.", false)
    );
  }
}

export async function createUser(request, role) {
  console.log(request);
  let response = await ApiService.post("user/create/" + role, request);
  if (response.data) {
    return response.data;
  }
}

export async function loadSettings() {
  let response = await ApiService.get("settings/find");
  return response.data;
}

export async function getUsersByIds(ids) {
  let response = await ApiService.post("user/getAllByIds", { ids: ids });
  return response.data;
}
export async function getUserById(id) {
  let response = await ApiService.get("user/get/" + id);
  return response.data;
}

export async function deleteUser(id) {
  let response = await ApiService.get("user/delete/" + id);
  return response.data;
}

export async function getProfessionsByIds(ids) {
  let response = await ApiService.post("user/professions/get", { ids: ids });
  if (response) return response.data;
  else return [];
}
export async function getAddressOfUser(id) {
  let response = await ApiService.get("address/get/" + id);
  return response.data;
}
export async function getOwnUserForLobby() {
  console.log("getOwnUserForLobby");
  let response = await ApiService.get("user/get/lobby/own");
  console.log("response", response);
  return response.data;
}
