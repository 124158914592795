<template>
  <div class="login-container">
    <!-- Video Control Button -->
    <v-btn
      icon
      size="large"
      class="video-control-btn"
      @click="toggleVideo"
      color="white"
      elevation="2"
    >
      <v-icon color="white" v-if="isVideoPlaying">mdi-pause</v-icon>
      <v-icon color="white" v-else>mdi-play</v-icon>
    </v-btn>

    <!-- Video Hintergrund -->
    <video ref="backgroundVideo" autoplay muted loop class="background-video">
      <source
        type="video/mp4"
        src="https://berlin-dance-music-event.com/wp-content/uploads/2023/08/c8e2266b-5301-46aa-9340-d7efcbe9714d.mp4"
      />
    </video>

    <!-- Login Card -->
    <v-card class="login-card mx-auto pa-6 rounded-lg" elevation="8">
      <div class="text-center mb-6">
        <v-img
          width="150"
          class="mx-auto"
          :src="require('@/assets/logoBig.webp')"
        ></v-img>
        <h1 class="text-h4 mt-4">Log In to your account</h1>
        <h4 class="mb-5 font-weight-regular text--secondary">
          Welcome back! Select method to log in.
        </h4>
      </div>

      <v-btn
        outlined
        color="grey"
        disabled
        size="large"
        class="social-login-btn mb-4"
        :loading="loading"
        :href="
          'https://bdme.auth.eu-central-1.amazoncognito.com/login?client_id=6fb837a28n4589dkr8r5c9n6vb&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=' +
          frontendhost +
          '/login'
        "
      >
        <span>Google/ Facebook</span>
      </v-btn>

      <div class="my-4 d-flex align-center" style="gap: 20px">
        <v-divider />
        <span class="text-disabled">or</span>
        <v-divider />
      </div>

      <v-form
        ref="form"
        class="d-flex justify-center flex-wrap"
        style="gap: 15px"
        v-model="valid"
      >
        <v-text-field
          variant="outlined"
          class="custom-login-input"
          rounded
          autocomplete="username"
          autofocus
          style="width: 200px"
          hide-details
          label="Email Address"
          type="email"
          v-model="user.email"
          required
        />
        <v-text-field
          label="Password"
          v-model="user.password"
          style="width: 100%"
          autocomplete="current-password"
          variant="outlined"
          rounded
          hide-details
          class="custom-login-input"
          @keypress.enter="handleLogin"
          :rules="passwordRules"
          type="password"
          required
        />

        <v-btn
          block
          color="accent"
          class="mx-auto align-self-center"
          :loading="loading"
          width="100px"
          @click="handleLogin"
        >
          <span>Login</span>
        </v-btn>
      </v-form>

      <div class="d-flex justify-end mt-2">
        <v-btn variant="plain" href="/u/reset/password/request"
          >Forgot Password?</v-btn
        >
      </div>

      <v-alert
        type="error"
        class="ma-3"
        v-if="message"
        dismissible
        color="red"
        >{{ message }}</v-alert
      >

      <div
        class="text-center mt-4 d-flex align-center justify-center"
        style="font-size: 0.8rem"
      >
        <span>Don't have an account?</span>
        <v-btn
          to="/register"
          variant="plain"
          class="text-lowercase text-decoration-underline"
          >Create an account</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import { getCognitoToken, loginWithCognito } from "@/services/cognito.service";
import Notification from "@/models/notification";

export default {
  name: "LoginView",
  data() {
    return {
      valid: true,
      user: {
        email: "",
        password: "",
      },
      message: "",
      loading: false,
      username: "",
      usernameRules: [
        (v) => !!v || this.$t("login.nameRequired"),
        (v) => (v && v.length <= 20) || this.$t("login.nameLength"),
      ],
      password: "",
      confirmPassword: "",
      passwordRules: [(v) => !!v || this.$t("login.passwordRequired")],
      isVideoPlaying: true,
    };
  },
  computed: {
    frontendhost() {
      return process.env.VUE_APP_FRONTHOST;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    //console.log("Der COde ist: "+this.$route.query.code);
    if (this.loggedIn) {
      this.$router.push("/profile");
    } else if (this.$route.query.code) {
      console.log("Client is logging in with Cognito");
      const promise = getCognitoToken(this.$route.query.code);
      promise.then((tokenObject) => {
        this.$store.state.loading.loginCognitoProcess.tokenObject = tokenObject;
        console.log("Token: " + tokenObject);
        if (tokenObject.extraEmail) {
          loginWithCognito(tokenObject);
        } else {
          this.$store.dispatch(
            "notificationsStore/add",
            new Notification("warning", "Please provide your email", false)
          );
        }
      });
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      /**
       this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }
       **/
      if (this.user.email && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/");
          },
          () => {
            this.loading = false;
            this.message = "You entered the wrong email or password";
          }
        );
      }
    },
    toggleVideo() {
      const video = this.$refs.backgroundVideo;
      if (this.isVideoPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isVideoPlaying = !this.isVideoPlaying;
    },
  },
};
</script>
<style scoped>
.login-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
  background: black;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.login-card {
  position: relative;
  z-index: 1;
  max-width: 500px;
  width: 90%;
  background: rgba(255, 255, 255, 1) !important;
}

.social-login-btn {
  height: 50px;
  width: 100%;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px -10px;
  border-color: rgb(238, 238, 238);
  border-radius: 50px;
}

.custom-login-input {
  width: 100% !important;
}

.video-control-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.3) !important;
}
</style>
