import { getAllEventsByDate } from "@/services/event.service";

export const events = {
  namespaced: true,
  state: {
    events: [],
    eventsGroupedByDay: [],
    favourites: [],
    page: 0,
  },
  actions: {
    setEventsGroupedByDate({ commit }, events) {
      commit("setEventsGroupedByDate", events);
    },
    async loadAllEventsOfDate({ commit }, date) {
      let response = await getAllEventsByDate(date);
      if (response) {
        commit("addToDay", { day: date, events: response });
      }
    },
    setEvents({ commit }, events) {
      commit("setEvents", events);
    },
    updateFavourites({ commit }, events) {
      commit("updateFavourites", events);
    },
  },

  mutations: {
    addToDay(state, dayAndEvents) {
      for (let day of state.eventsGroupedByDay) {
        if (day === dayAndEvents.day) {
          day.events = dayAndEvents.events;
        }
      }
    },
    setEvents(state, events) {
      state.events.push(...events);
    },
    updateFavourites(state, events) {
      state.favourites = events;
    },
    setEventsGroupedByDate(state, events) {
      state.eventsGroupedByDay = events;
    },
  },
};
