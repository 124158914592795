<template>
  <ViewHeadline headline-text="My Timetable" />
  <v-container>
    <v-sheet color="transparent" min-height="800px" class="pa-7">
      <v-list color="transparent">
        <v-skeleton-loader
          v-for="n in 5"
          v-if="loading"
          class="mx-auto my-5"
          key="n"
          type="avatar, article"
        ></v-skeleton-loader>

        <div v-else-if="$store.state.events.favourites.length <= 0">
          <h3 class="text-center">No favourites added yet</h3>
        </div>
        <div
          class="d-flex justify-center flex-wrap"
          v-else-if="$store.state.events.favourites.length <= 0 && !currentUser"
        >
          <h3 style="width: 100%" class="text-center">
            Login to add favourites and build your personal timetable
          </h3>
          <v-btn class="my-3" @click="$router.push('/login')">Login</v-btn>
        </div>
        <div
          class="d-flex justify-center flex-wrap"
          v-else-if="$store.state.events.favourites.length <= 0"
        >
          <h3 style="width: 100%" class="text-center">
            Start building your personal timeline
          </h3>
          <v-btn class="mx-auto text-center" href="/events" plain
            >To Eventspage</v-btn
          >
        </div>
        <div v-else>
          <div v-for="(day, name) in groupEventsByDate" :key="name">
            <h3 class="text-color-accent mb-4 mt-2">{{ name }}</h3>
            <v-list color="transparent">
              <div v-for="(event, index) of day" :key="event.id">
                <EventItem
                  :event="event.event"
                  :artists="event.artists"
                  @removeFromFavourites="removeFavourite(event.event.id)"
                />
                <v-divider
                  v-if="index < $store.state.events.events.length - 1"
                  :key="index"
                ></v-divider>
              </div>
            </v-list>
          </div>
        </div>
      </v-list>
    </v-sheet>
  </v-container>
</template>

<script>
import { getSpecificEvents } from "@/services/event.service";
import EventItem from "@/views/Event/EventItem.vue";
import ViewHeadline from "@/components/helpers/ViewHeadline";
export default {
  name: "FavouritesView",
  components: { EventItem, ViewHeadline },
  data() {
    return {
      favourites: [],
      loading: false,
    };
  },
  created() {
    if (this.currentUser) {
      this.getFavourites();
    }
  },
  methods: {
    async getFavourites() {
      this.loading = true;
      let response = await getSpecificEvents(this.currentUser.favourites);
      this.$store.dispatch("events/updateFavourites", response);
      this.loading = false;
    },
    removeFavourite(id) {
      Object.keys(this.groupEventsByDate).forEach((date) => {
        this.groupEventsByDate[date] = this.groupEventsByDate[date].filter(
          (item) => item.event.id !== id
        );
      });
    },
  },
  computed: {
    groupEventsByDate() {
      let moment = require("moment"); // require
      return this.$store.state.events.favourites.reduce(function (
        groups,
        item
      ) {
        var name = moment(item.event.dateTime).format("dddd[,] DD[.]MM");
        var group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
      },
      {});
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style scoped></style>
