<script>
import MyOrders from "@/components/order/MyOrders.vue";
import UserCart from "@/components/cart/UserCart.vue";
import { getBill } from "@/services/ticket.service";
import Notification from "@/models/notification";

export default {
  name: "CartDrawer",
  computed: {
    show() {
      return this.$store.state.cart.show;
    },
    cart() {
      return this.$store.state.cart.cart;
    },
    totalItems() {
      return this.cart.length;
    },
    total() {
      let Euro = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });
      let response = 0;
      for (let item of this.cart) {
        response += item.amount * item.product.price;
      }
      return Euro.format(response);
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    toggleCart() {
      this.$store.commit("cart/toggleCart");
    },
    async generateBill() {
      let data = await getBill(this.cart);
      if (data) {
        this.bill = data;
        this.toggleCart();
        this.$router.push("/checkout");
      } else
        this.$store.dispatch(
          "notificationsStore/add",
          new Notification(
            "error",
            "Error validation your order. Please try again.",
            false
          )
        );
    },
  },
  components: { UserCart, MyOrders },
};
</script>

<template>
  <!-- Cart Drawer -->
  <v-navigation-drawer
    v-model="show"
    location="right"
    temporary
    style="z-index: 9999"
    width="500"
  >
    <v-toolbar color="white" flat>
      <v-btn icon @click="toggleCart">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-toolbar-title>
        <h2>
          My Cart
          <v-icon color="primary" size="24" class="ml-2">mdi-cart</v-icon>
        </h2>
      </v-toolbar-title>

      <MyOrders v-if="currentUser" />
    </v-toolbar>

    <v-divider></v-divider>

    <!-- Hauptcontainer mit flexbox -->
    <div class="drawer-content">
      <!-- Scrollbarer Bereich -->
      <div class="cart-items-container">
        <v-container style="padding-top: 0px; padding-bottom: 0px">
          <UserCart />
        </v-container>
      </div>

      <!-- Footer -->
      <div class="cart-footer">
        <v-container style="padding-top: 0px">
          <v-divider class="mb-5"></v-divider>

          <div class="d-flex justify-space-between mb-4">
            <div class="font-weight-bold">Total</div>
            <div class="font-weight-bold" v-text="total"></div>
          </div>

          <v-btn
            color="accent"
            @click="generateBill"
            :disabled="totalItems <= 0"
            block
            class="mb-4 custom-border-radius"
          >
            Go to checkout
          </v-btn>
        </v-container>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style scoped>
.drawer-content {
  height: calc(100% - 64px); /* Höhe abzüglich Toolbar */
  display: flex;
  flex-direction: column;
}

.cart-items-container {
  flex: 1;
  overflow-y: auto;
}

.cart-footer {
  background: white;
}
</style>
